#root {
    position: relative !important;
}

.container {
    background-color: #fff;
    width           : 100vw;
}

.Styles_result {
}

.Styles_header {
    margin-top: 1.386667rem !important;
    position  : relative;
    width     : 100vw;
    height    : 4.4rem;
    background: linear-gradient(125deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.46) 100%);
}

.Styles_header_img {
    width   : 100vw;
    height  : 4.4rem;
    position: relative;
}


.Styles_result_name {
    font-size  : .586667rem;
    font-weight: 600;
    color      : #FFFFFF;
    line-height: .8rem;
    position   : absolute;
    top        : 1.12rem;
    z-index    : 11;
    left       : .64rem;
}

.Retest {
    width   : 3.36rem;
    height  : .64rem;
    position: absolute;
    top     : 2.16rem;
    left    : .64rem;
    z-index : 11;
}

.Styles_result_content {
    width        : 100%;
    background   : #FFFFFF;
    border-radius: .4rem;
    z-index      : 999;
    margin-top   : -0.4rem;
    position     : absolute;
}

.result_desc {
    font-size  : .373333rem;
    font-weight: 400;
    color      : #3E3E3F;
    line-height: .586667rem;
    padding    : .533333rem .64rem .906667rem .64rem;

}


.design_scheme {
    padding: 0 .32rem;
}

.design_scheme_title {
    font-size  : .48rem;
    font-weight: 500;
    color      : #3E3E3F;
    line-height: .666667rem;
    font-family: PingFangSC-Medium, PingFang SC;
}

input.design_scheme_ipt {
    width        : 8.72rem;
    height       : 1.146667rem;
    background   : #F7F7F7;
    border-radius: .586667rem;
    border       : none;
    padding      : .266667rem .88rem;
    margin       : .426667rem 0 .16rem 0.28rem;
}

.design_scheme_notes {
    font-size      : .32rem;
    font-weight    : 400;
    color          : #979797;
    line-height    : .453333rem;
    display        : flex;
    justify-content: center;
}

.design_scheme_btn {
    width          : 8.773333rem;
    height         : 1.066667rem;
    background     : linear-gradient(180deg, #02BBFF 0%, #0364BD 100%);
    border-radius  : .053333rem;
    font-size      : .426667rem;
    font-weight    : 500;
    color          : #FFFFFF;
    line-height    : .586667rem;
    display        : flex;
    justify-content: center;
    align-items    : center;
    margin         : .32rem auto .373333rem auto;
}

.RecommendedCases {
    padding   : 0 0 .96rem 0;
    box-sizing: border-box;


}

.RecommendedCases_title {
    font-size    : .32rem;
    font-family  : PingFangSC-Regular, PingFang SC;
    font-weight  : 400;
    color        : #979797;
    line-height  : .453333rem;
    margin-bottom: .16rem;
    margin-left  : .32rem;
}


/* 关联的设计师 */
.Designer {
    border-bottom : .026667rem solid #E1E1E1;
    padding-bottom: 1.066667rem;

}

.Connected_designers {
    font-size   : .48rem;
    font-family : PingFangSC-Medium, PingFang SC;
    font-weight : 500;
    color       : #3E3E3F;
    line-height : .666667rem;
    padding-left: .32rem;
}

.Designer_img {
    width        : 2.453333rem;
    height       : 2.453333rem;
    border-radius: 50%;

}

.Designer_info {
    display           : flex;
    /* align-items    : center; */
    justify-content   : center;
    padding           : .533333rem .533333rem .266667rem .213333rem;
}


.Designer_desc {
    margin-left: .453333rem;
}

.Designer_title {}

.Designer_name {
    font-size   : .426667rem;
    font-family : PingFangSC-Medium, PingFang SC;
    font-weight : 500;
    color       : #3E3E3F;
    line-height : .586667rem;
    margin-right: .16rem;

}

.Designer_identity {
    font-size  : .32rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color      : #0166B3;
    line-height: .453333rem;

}

.subscribe {
    width          : 2.266667rem;
    height         : .693333rem;
    background     : #0672D5;
    border-radius  : .426667rem;
    font-size      : .373333rem;
    font-family    : PingFangSC-Regular, PingFang SC;
    font-weight    : 400;
    color          : #FFFFFF;
    line-height    : .533333rem;
    display        : flex;
    justify-content: center;
    align-items    : center;
    position       : absolute;
    right          : .533333rem;
    margin-top     : -0.533333rem;
}

.Designer_other {

    font-size  : .32rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color      : #3E3E3F;
    line-height: .453333rem;

}

.Designer_detail {
    padding: 0 .533333rem .266667rem .213333rem;

}

.Designer_detail_text {
    font-size         : .32rem;
    font-family       : PingFangSC-Regular, PingFang SC;
    font-weight       : 400;
    color             : #868686;
    line-height       : .453333rem;
    overflow          : hidden;
    display           : -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow     : ellipsis;
    -webkit-line-clamp: 2;
    position          : relative;
}

.Designer_more {
    color      : #0185FF;
    font-size  : .32rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: .453333rem;
    position   : absolute;
    right      : .64rem;
    margin-top : -0.426667rem;
}


.Designer_works_wrap {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    padding: 0 .32rem;
    .Designer_works_wrap_list{
    
    }
}
.Designer_works_wrap::-webkit-scrollbar {
    display: none
}
.Designer_works {
    width : 3.6rem;
    height: 2.026667rem;

}

/* 案例 */

.RecommendedCase_wrap {
    display       : flex;
    flex-direction: row;
    overflow-x    : scroll; 
    margin-top: 0.3rem;
}
.RecommendedCase_list{
    margin-left: .32rem;
}
.RecommendedCase_wrap::-webkit-scrollbar {
    display: none
}

.caseImg {
    width        : 4.8rem;
    height       : 2.693333rem;
    box-shadow   : 0rem .053333rem .106667rem 0rem rgba(0, 0, 0, 0.1);
    border-radius: .053333rem;

}

.RecommendedCase_info {
    display: flex;
}

.Case_title {
    font-size  : .373333rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color      : #3E3E3F;
    line-height: .533333rem;

}

.Case_area {
    margin-left:auto;
    font-size  : .373333rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color      : #3E3E3F;
    line-height: .533333rem;

}

.label_wrap {}

.label {
    margin-right  : .213333rem;
    width        : 1.84rem;
    height       : .533333rem;
    background   : #E6F4FF;
    font-size    : .32rem;
    font-family  : PingFangSC-Regular, PingFang SC;
    font-weight  : 400;
    color        : #0166B3;
    line-height  : .453333rem;
    border-radius: .266667rem;
}