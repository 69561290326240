.container {
    background-color: #f7f7f7;
}

.Style_tests {}

.Style_banner {
    width: 100vw;
    margin-top: 1.386667rem !important;
}

.Style_banner img {
    width: 100%;
    height: 5.44rem;
    background: rgba(0, 0, 0, 0.22);
}

.Style_main {
    border-radius: .4rem;
    background-color: #fff;
    position: absolute;
    margin: -1.8rem .213333rem 0 .213333rem;
    box-sizing: border-box;
    padding: .586667rem .32rem .106667rem .48rem;
    height: 10.986667rem;

}

.Style_topic {
    display: flex;
    align-items: center;
    padding-bottom: .533333rem;

}


.Style_topic_num {
    width: 1.093333rem;
    height: 1.093333rem;
    background: #7EC1FF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Style_topic_num {
    font-size: .48rem;
    font-weight: 600;
    color: #FFFFFF;

}

.Style_topic p {
    font-size: .373333rem;
    font-weight: 600;
    color: #3E3E3F;
    line-height: .533333rem;
    margin-left: .133333rem;
    display: flex;
    align-items: center;
    flex: 1;
    height: 1.066667rem;
}



.Style_topic_content {
    display: flex;
    flex-wrap: wrap;
}

.Style_topic_content_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.Style_topic_content img {
    width: 4.16rem;
    height: 2.08rem;
}

.Style_topic_content span {
    display: inline-block;
    padding-bottom: .32rem;
    line-height: .533333rem;
    font-size: .373333rem;
    font-weight: 400;
    color: #3E3E3F;
    margin-top: .16rem;

}

.footers {
    height: 7.2rem;
}

.Style_footer img {
    height: 3.946667rem;
    width: 100%;
    margin-top: -.6rem;
}