.myNavBar{
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100vw;
}
.myNavBar .am-icon-md{
  width: .746667rem;
  height: .746667rem;
}
.myNavBar_logo {
    width          : 3.04rem;
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
}

.logoTop {
    height: .426667rem;
}

.logoBottom {
    height: .266667rem;
}


.am-navbar{
    height: 1.386667rem !important;
}