.contents{
    margin: 0 .753333rem;
}
.containers_top{
    display: flex;
    flex-direction: row;  align-items: center;
    margin: 0 .4rem 0 .4rem;

}
.neighbor_case_content_rightt{
  
}
.neighbor_case_content_rs{
    display: flex;
    flex-direction: row;
    margin-left: .266667rem;
}
.content_sg{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-left: .933333rem;
    margin-top: .133333rem;
}
.content_sg span{
    width: .933333rem;
}
.contents{
    margin-top: .533333rem;
}
.tupians{
    width: 2.666667rem;
    height: 2.666667rem;
    margin-right: .133333rem;
}
.tupian_s{
    display: flex;
    margin-top: .24rem;
    flex-wrap: wrap;
}