.container {
    background-color: #F7F7F7;

}

.SiteList {
    // margin-top: 1.173333rem;
}



.filterWrap {
    width           : 100vw;
    height          : 1.066667rem;
    background-color: #fff;
    z-index         : 999;
    .filter_header {
        padding-top    : .32rem;
        padding-bottom : .32rem;
        display        : flex;
        align-items    : center;
        justify-content: space-around;

        .filterWrap_title {
            flex           : 1;
            display        : flex;
            justify-content: center;
            align-items    : center;

            .filterWrap_name {
                font-size  : .346667rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color      : #000000;
                line-height: .533333rem;
            }

            .downArrowIcon {
                width      : .293333rem;
                height     : .16rem;
                margin-left: .133333rem;

            }
        }
    }

    .filterWrap_list {
        background-color: #fff;
        position        : absolute;
        width           : 100%;
        padding-bottom  : 1.333333rem;
        margin-top      : -1em;
        height          : 11.2rem;
        overflow        : scroll;

        .filterWrap_list_wrap {
            margin-left: 0!important;
            float         : left;
            display       : flex;
            flex-direction: column;
            align-items   : center;

            .default_item {
                font-size  : .373333rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color      : #005BAB;
                line-height: .96rem;
            }

            div.filterWrap_list_item {
                span.filterWrap_list_label {
                    font-size  : .373333rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color      : #000000;
                    line-height: .96rem;

                }
            }
        }

    }
}



.SiteList_banner {
    padding: 0 .266667rem 0 .266667rem;

    .SiteList_bannerImg {
        width : 100%;
        height: 2.666667rem;
    }
}


.siteListWrap {
    padding: .266667rem;

    .siteListItem {
        background-color: #fff;
        margin-bottom   : .4rem;
        border-radius   : .133333rem;
        overflow        : hidden;

        img.site_img {
            width : 100%;
            height: 5.333333rem;
        }

        .siteListInfo {
            padding: .266667rem .586667rem;

            .siteListHeader {
                display        : flex;
                justify-content: space-between;

                .site_name {
                    font-size     : .426667rem;
                    font-family   : PingFangSC-Semibold, PingFang SC;
                    font-weight   : 600;
                    color         : #000000;
                    line-height   : .586667rem;
                    padding-bottom: .266667rem;
                }

                .siteAddress {
                    img.addressIcon {
                        width : .393333rem;
                        height: .426667rem;
                        margin-bottom: .1rem;
                        margin-right: .1rem;
                    }

                    .site_address {
                        font-size  : .373333rem;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color      : #000000;
                        line-height: .533333rem;

                    }
                }
            }

            p.site_houseInfo {
                font-size     : .373333rem;
                font-family   : PingFangSC-Regular, PingFang SC;
                font-weight   : 400;
                color         : #000000;
                line-height   : .533333rem;
                padding-bottom: .16rem;
            }

            p.site_designer {
                font-size     : .373333rem;
                font-family   : PingFangSC-Regular, PingFang SC;
                font-weight   : 400;
                color         : #000000;
                line-height   : .533333rem;
                padding-bottom: .4rem;

            }
        }


    }

    .nomore {
        font-size     : .373333rem;
        font-family   : PingFangSC-Regular, PingFang SC;
        font-weight   : 400;
        color         : #7E7E7E;
        line-height   : .533333rem;
        text-align    : center;
        padding-top   : 1.28rem;
        padding-bottom: .746667rem;
    }
}


.siteList_progress {
    width     : 100%;
    overflow-x: scroll;
}

.siteList_progress::-webkit-scrollbar {
    display: none;
}