.storage_test-title {
	display         : flex;
	width           : 8.533333rem;
	height          : 10.666667rem;
	border-radius   : .453333rem;
	background-image: url(../../image/bg_form_DesignAppointment.png);
	background-size : cover;
}

.storage_dw {
	left    : 4.5rem;
	margin-top     : .266667rem;
	position: absolute;
	top: 11rem;
}

.ant-modal-body {
	width  : 0;
	height : 0;
	padding: 0 !important;
}

.ant-modal-content {
	background-color: transparent !important;
	width           : 0;
	height          : 0;
	top             : -6.666667rem;
}

.storage_test-title {
	margin-left : 0;
	padding-left: 0;

}

.storage_title_ {
	height     : .586667rem;
	font-size  : .426667rem;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color      : #333333;
	line-height: .586667rem;
	margin-top : .533333rem;
	margin-bottom: .266667rem;
}

.title_f_p {
	color      : #0166B3;
	margin     : 0 .08rem;
	font-weight: 600;
}

.storage_title_f {
	display       : flex;
	flex-direction: row;
	justify-content: center;
	width         : 100%;
	height        : .453333rem;
	font-size     : .32rem;
	font-family   : PingFangSC-Regular, PingFang SC;
	font-weight   : 400;
	color         : #9E9E9E;
	line-height   : .453333rem;
	margin-top    : .08rem;
	margin-bottom : .266667rem;
	width         : 100%;
}

.storage_input {
	margin-bottom: .213333rem !important;
}

.errorTel {
	font-size  : .426667rem;
	transform: scale(0.7);
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color      : #FF2020;
	line-height: .323333rem;
	margin-left: -2.133333rem;
	margin-top: .133333rem;
	height: 0;
}	

.square {
	position   : absolute;
	z-index    : 99;
	margin-top : -0.96rem;
	margin-left: 2.133333rem;
	font-size  : .373333rem;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color      : #3E3E3F;
	line-height: .533333rem;
}

.storage_button {
	width          : 6.08rem;
	height         : 1.12rem;
	background     : linear-gradient(160deg, #029FDA 0%, #0166B3 100%);
	border-radius  : .56rem;
	font-size      : .426667rem;
	color          : #FFFFFF;
	line-height    : .586667rem;
	display        : flex;
	justify-content: center;
	align-items    : center;
	margin         : 0 auto;
	margin-top     : .693333rem;
	margin-bottom  : .586667rem;
}

input.ant-input {
	width        : 6.28rem !important;
	height       : .986667rem;
	border-radius: .063333rem;
	border       : .026667rem solid #DEDEDE;
}
input.ant-input::-webkit-input-placeholder {
	transform: scale(0.8);
	position: relative;
	left: -0.723333rem;
  }
  
input.ant-input:-moz-placeholder {
	transform: scale(0.8);
	position: relative;
	left: -0.723333rem;
}
  
input.ant-input::-moz-placeholder {
	transform: scale(0.8);
	position: relative;
	left: -0.723333rem;
}
  
input.ant-input:-ms-input-placeholder {
	transform: scale(0.8);
	position: relative;
	left: -0.723333rem;
}

.storage_bottomSpan {
	font-size   : .32rem;
	font-weight : 400;
	color       : #0166B3;
	line-height : .453333rem;
	margin-right: .266667rem;
}


div.storage_Bottom {
	display: flex;
	align-items: center;
	justify-content: center;
	img.iconClock {
		width: .533333rem;
		height: .533333rem;
		margin-right: .266667rem;
	}
  
	p.storage_Bottom_txt {
		font-size: .32rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #0166B3;
		line-height: .453333rem;
		display: flex;
		.storage_Bottom_span{
			color: #FF2020;
			margin: 0 .053333rem;
		}
	}
  }