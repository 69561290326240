@font-face {
  font-family: "iconfont"; /* Project id 2268594 */
  src: url('iconfont.woff2?t=1650767674417') format('woff2'),
       url('iconfont.woff?t=1650767674417') format('woff'),
       url('iconfont.ttf?t=1650767674417') format('truetype'),
       url('iconfont.svg?t=1650767674417#iconfont') format('svg');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconweibiaoti2fuzhi15:before {
  content: "\e62c";
}

.iconshejishijianzhushi:before {
  content: "\e635";
}

.iconzixun:before {
  content: "\e63c";
}

.iconceshi:before {
  content: "\e663";
  font-size: 16px;
  position: relative;
  bottom: 4px;
}

