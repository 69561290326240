.Designers {
    background-color: #fff;
    padding-top: 1.2rem;
}

.Designersss {
    background-color: #fff;
    // padding-top: 1.2rem;
}

.filter_header {
    padding-bottom: .32rem !important;
}
.loadMore {
    background-color: #F7F7F7;
    text-align: center;
    height: 1rem;
    line-height: 1rem;
}

ul.DesignerListWrap {
    width: 100%;

    li.DesignerList {
        padding: .533333rem 0 .533333rem .533333rem;
        position: relative;

        div.DesignerList_top {
            padding-right: .533333rem;
            display: flex;
            align-items: center;

            div.DesignerList_top_L {
                img.designer_avator {
                    width: 2.453333rem;
                    height: 2.453333rem;
                    border-radius: 50%;
                }
            }

            div.DesignerList_top_R {
                flex: 1;
                margin-left: .426667rem;
                font-size: .32rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #3E3E3F;
                line-height: .693333rem;

                div.DesignerList_name_identity {
                    display: flex;
                    align-items: flex-end;

                    span.DesignerList_name {
                        display: block;
                        max-width: 2.5rem;
                        font-size: .426667rem;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #3E3E3F;
                        height: 0.693333rem;
                        line-height: 0.693333rem;
                        margin-right: .06rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                    }

                    span.DesignerList_identity {
                        height: 0.693333rem;
                        line-height: 0.693333rem;
                        display: block;
                        font-size: .32rem;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #0166B3;
                    }
                }

                span.experience {}

                p.designer_style {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    display: -webkit-box;
                }

                span.designer_scope {}


            }
        }

        .designer_subscribe {
            position: absolute;
            right: .5rem;
            width: 2.266667rem;
            height: .693333rem;
            background: #0672D5;
            border-radius: .426667rem;
            font-size: .373333rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: .533333rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        div.DesignerList_bottom {
            font-size: .32rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #868686;
            line-height: .453333rem;
            padding: .266667rem .533333rem .266667rem 0;

            span.DesignerList_bottom_desc {
                p{
                    position: relative;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    line-height: .4rem;
                     &::after {
                         background-color: #fff;
                         padding: 0 .15rem;
                         position: absolute;
                         right: 0;
                         top: .4rem;
                         content: '查看更多';
                         color: #1890FF;
                     }
                }
            }

            .DesignerList_more_desc {
                color: #0185FF;
            }
        }

        .DesignerList_bottom_imgs {
            overflow-x: scroll;
            display: flex;

            .DesignerList_bottom_imgBox {
                .DesignerList_bottom_img {
                    width: 3.6rem;
                    height: 2.026667rem;
                    margin-right: .213333rem;
                }
            }


        }

        .DesignerList_bottom_imgs::-webkit-scrollbar {
            display: none;
        }
    }
}

.filterWrap_list {
    background-color: #fff;
    position: absolute;
    width: 100%;
    padding-bottom: 1.333333rem;
    margin-top: -1em;
    height: 11.2rem;
    overflow: scroll;
    z-index: 999;
}

div.bannerTop {
    width: 100%;
    height: 2.666667rem;
    position: relative;
    // margin-top: 1.173333rem;

    img.bannerTop_img {
        width: 100%;
        height: 100%;
    }


}