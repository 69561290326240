div.transparentBackground {
    width           : 100vw;
    height          : 100vh;
    background-color: rgba($color: #000000, $alpha: 0.49);
    overflow-y: scroll;

    div.FoundPanel {
        width     : 100%;
        background: #FFFFFF;
        margin-top: 2.026667rem;
    }
}


ul.FoundPanel_top {
    width          : 100%;
    display        : flex;
    height         : 1.973333rem;
    align-items    : center;
    justify-content: space-around;

    li.FoundPanel_top_list {
        .FoundPanel_top_link {
            display        : flex;
            flex-direction : column;
            justify-content: space-between;
            align-items    : center;

            img.FoundPanel_top_img1 {
                width : .64rem;
                height: .8rem;
            }

            img.FoundPanel_top_img2 {
                width : .613333rem;
                height: .8rem;
            }

            img.FoundPanel_top_img3 {
                width : .746667rem;
                height: .8rem;
            }

            span.FoundPanel_top_txt {
                font-size  : .426667rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color      : #000000;
                line-height: .586667rem;
                padding-top: .106667rem;
            }
        }
    }
}

.transitionStrip {
    width     : 100%;
    height    : .266667rem;
    background: #F2F2F2;
}

.FoundPanel{
    height: auto;
    padding-bottom: 1.566667rem;
}

ul.FoundPanel_middle {
    min-height: 100vh;
    overflow-y: scroll;
    padding-bottom: 1.333333rem;
    li.FoundPanel_middle_list {
        div.FoundPanel_middle_list_header {
            display      : flex;
            align-items  : center;
            border-bottom: .026667rem solid #F2F2F2;
            padding      : .373333rem .533333rem .266667rem .373333rem;

            img.FoundPanel_middle_img {}

            span.FoundPanel_middle_tit {
                font-size  : .426667rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color      : #000000;
                line-height: .586667rem;
                margin-left: .373333rem;
            }
        }

        ul.FoundPanel_middle_links {
            padding        : .266667rem .533333rem .266667rem .373333rem;
            display        : flex;
            // justify-content: space-evenly;
            align-items    : center;
            padding-bottom : .266667rem;

            .FoundPanel_middle_link {
                font-size  : .32rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color      : #025BAC!important;
                line-height: .373333rem;
                margin-right: .3rem;

            }
        }

    }
}

.FoundPanel_bottom{
    position: fixed;
    height: 1.333333rem;
    display: flex;
    padding-left: .533333rem;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background-color: #fff;
    flex-direction: column;
    justify-content: center;
    font-size: .346667rem;
}