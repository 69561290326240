.neighborCase {
    background-color: #fff;
    position: relative;
    .neighborCase_banner {
        width : 100%;
        height: 5.466667rem;

        .neighborCase_bannerImg {
            width : 100%;
            height: 100%;

        }
    }
}


.neighborCaseInfo {
    width          : 9.333333rem;
    height         : 5.146667rem;
    border-radius  : .426667rem;
    margin-top     : -1.253333rem;
    background     : rgba(255, 255, 255, 0.88);
    position       : absolute;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    left           : 50%;
    transform      : translateX(-50%);

    .communityName {
        font-size  : .426667rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color      : #3E3E3F;
        line-height: .586667rem;
        text-align : center;
        padding    : 0 0 .373333rem 0;
    }

    ul.communityList {
        margin-left: .8rem;

        li.communityItem {
            display    : flex;
            align-items: center;
            width: 100%!important;
            .communityList_title {
                font-size   : .373333rem;
                font-family : PingFangSC-Regular, PingFang SC;
                font-weight : 400;
                color       : #BBBBBB;
                line-height : .666667rem;
                margin-right: .426667rem;
                width       : 1.866667rem;
                text-align  : right;
            }

            .communityList_desc {
                font-size  : .373333rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color      : #3E3E3F;
                line-height: .666667rem;
            }
        }
    }

    .communityBtns {
        display : flex;
        position: absolute;
        bottom  : 0.8rem;
        right   : .426667rem;

        .communityBtn {
            height         : .533333rem;
            background     : #FF8618;
            border-radius  : .426667rem;
            padding        : 0 .32rem;
            display        : flex;
            justify-content: center;
            align-items    : center;
            font-size      : .373333rem;
            font-family    : PingFangSC-Regular, PingFang SC;
            font-weight    : 400;
            color          : #FFFFFF;
            line-height    : .533333rem;
        }
    }

    .line {
        width           : 100%;
        height          : .026667rem;
        background-color: #979797;
        opacity         : 0.3;

    }
}

.sectionTitle {
    font-size  : .48rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color      : #3E3E3F;
    line-height: .666667rem;
    padding-left: .32rem;
}

.analysisHouseType {
    position  : relative;
    width     : 100%;
    padding   : .8rem .32rem 1.066667rem .32rem;
    margin-top: 4rem;

    .analysisHouse_img {
        height: 4.773333rem;
        width : 100%;
    }
}

.caseIntroduction {
    padding: 0 .32rem 0 .32rem;
    width  : 100%;
    height: auto;
    margin-top: 4rem;
    .richTextWrap {
        width : 100%!important;
        height: auto;
        li{
            width : 100%!important;
        }
        img{
            width: 100%!important;
            
        }
    }
}


.guessLike {
    padding: .32rem;
    padding-bottom: 1.52rem;
    .section_title {
        font-size    : .48rem;
        font-family  : PingFangSC-Regular, PingFang SC;
        font-weight  : 400;
        color        : #3E3E3F;
        line-height  : .666667rem;
        margin-top   : 1.066667rem;
        margin-bottom: .8rem;
    }

    .guessLikeWrap {
        display        : flex;
        flex-wrap      : wrap;
        justify-content: space-between;

        .guessLikeList {
            margin-bottom: .533333rem;
            position     : relative;

            img.guessLikeImg {
                width : 4.56rem;
                height: 3.52rem;
            }

            .guessLikeList_seen {
                position       : absolute;
                top            : .213333rem;
                left           : .266667rem;
                display        : flex;
                align-items    : center;
                justify-content: center;
                width          : 2.96rem;
                height         : .613333rem;
                background     : rgba(0, 0, 0, 0.36);
                border-radius  : .32rem;

                img.seenEye_img {
                    // width : .32rem;
                    height: .32rem;
                }

                .seenPeople {
                    margin-left: .213333rem;
                    font-size  : .32rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color      : #FFFFFF;
                    line-height: .453333rem;

                }
            }

            .guessLike_info {
                padding-top    : .133333rem;
                font-size      : .32rem;
                font-family    : PingFangSC-Medium, PingFang SC;
                font-weight    : 500;
                color          : #3E3E3F;
                line-height    : .453333rem;
                display        : flex;
                justify-content: space-between;
                align-items    : center;

                .guessLike_address {}

                .guessLike_area {}
                .guessLike_label{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

}



.BottomBtns {
    display        : flex;
    justify-content: space-around;
    align-items    : center;
    font-size      : .373333rem;
    font-family    : PingFangSC-Regular, PingFang SC;
    font-weight    : 400;
    color          : #3E3E3F;
    line-height    : .533333rem;
    height         : 1.82rem;
    position       : fixed;
    bottom         : 0;
    z-index        : 999;
    width          : 100vw;
    background-color: #fff;
    .hate {
        img.hate_img {
            width       : .693333rem;
            height      : .693333rem;
            margin-right: .213333rem;
        }

        span {}
    }

    .like {
        img.like_img {
            width       : .693333rem;
            height      : .693333rem;
            margin-right: .213333rem;
        }

        span {}
    }

    .getAllCases {
        width          : 4.613333rem;
        height         : .853333rem;
        background     : #0166B3;
        border-radius  : .533333rem;
        font-size      : .426667rem;
        font-family    : PingFangSC-Regular, PingFang SC;
        font-weight    : 400;
        color          : #FFFFFF;
        line-height    : .586667rem;
        display        : flex;
        justify-content: center;
        align-items    : center;
    }
}