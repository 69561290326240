.decorateDetail {
    width     : 100%;
    height    : 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.newsLists {
    border-bottom: 1px solid #BBBBBB;
    margin-bottom: .4rem;
}
.questionTops{
    position: relative;
    box-sizing: border-box;
    .bannerTop_img{
        width: 100%;
        height: 100%;
    }
}
.Detail_all {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    margin-bottom  : .5rem;

    .all_r {
        display       : flex;
        flex-direction: row;
        line-height   : .6rem;
        p {
            padding-right: .3rem;
            padding-top  : .08rem;
            width: 2rem;
        }
    }

    .all_l {
        font-size  : 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color      : #283B4D;
    }
}

.decorateDetailBanner {
    // padding: .266667rem .533333rem 0 .533333rem;
}

.decorateDetailContent {
    display       : flex;
    flex-direction: column;
    padding       : .506667rem;
}

.CaseList_infosI {
    display        : flex;
    flex-direction : row;
    justify-content: space-around;
}

.DetailTimes {
    display        : flex;
    flex-direction : row;
    font-size      : .32rem;
    font-family    : PingFangSC-Regular, PingFang SC;
    font-weight    : 400;
    color          : #3E3E3F;
    line-height    : .506667rem;
    margin-bottom  : .4rem;
    justify-content: space-between;
}

.DetailContentd {
    p {
        span {
            font-size     : .373333rem !important;
            line-height   : .586667rem !important;
            text-align    : justify !important;
            display       : inline-block;
            vertical-align: top;

            &::after {
                // display: inline-block;
                // width: 100%;
                // content: '';
                // height: 0;
            }
        }

    }

    #text {
        text-indent: 1rem;
    }
}

.DetailContentd img {
    width : 100% !important;
    height: 100% !important;
}

.icon_dzz {
    width         : 4.053333rem;
    height        : .88rem;
    border-radius : .453333rem;
    border        : .026667rem solid #108ee9;
    display       : flex;
    flex-direction: column;
    padding       : .133333rem .133333rem .133333rem .4rem;
}

.icon_dzz .iconfont {
    color: #108ee9;
}

.icon-dianzan {
    margin-left : .266667rem;
    margin-right: .4rem;
    color       : #BBBBBB;
    font-size   : .533333rem;
    line-height : .533333rem;
}

.icon-chaping {
    margin     : .08rem .4rem 0 .266667rem;
    color      : #BBBBBB;
    font-size  : .533333rem;
    line-height: .533333rem;
}

.dianzan_ {
    display       : flex;
    flex-direction: row;
    width         : 100%;
    height        : .88rem;
}

.dianzan {
    width          : 100%;
    height         : 2.666667rem;
    display        : flex;
    flex-direction : row;
    justify-content: space-evenly;
    padding-right  : .506667rem;
    font-size      : .4rem;
    font-family    : PingFangSC-Regular, PingFang SC;
    font-weight    : 400;
    color          : #3E3E3F;
    margin-top     : .8rem;
}

.icon_dz {
    width         : 4.053333rem;
    height        : .88rem;
    border-radius : .453333rem;
    border        : .026667rem solid #BBBBBB;
    display       : flex;
    flex-direction: column;
    padding       : .133333rem .133333rem .133333rem .4rem;
}

.DetailTime {
    margin-right: 25%;
    font-size   : .32rem;
}

.TitleImg {
    width        : 9.866667rem;
    height       : 1.92rem;
    margin-bottom: .266667rem;
    padding-right: .8rem;
}

.Detailtitle {
    /* width: 10.08rem; */
    background-color  : #fff;
    font-size         : .533333rem;
    font-family       : PingFangSC-Regular, PingFang SC;
    font-weight       : 400;
    color             : #3E3E3F;
    line-height       : .746667rem;
    padding-bottom    : .506667rem;
    padding-top       : .24rem;
    display           : -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow     : ellipsis;
    overflow          : hidden;
}

.preciseSolution {
    font-size  : .32rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color      : #FFFFFF;
    line-height: .453333rem;
    margin-top : 1.706667rem;
    z-index    : 10;
    position   : absolute;
    right      : .96rem;
}
.bannerTops{
    width     : 100%;
    height    : 2.666667rem;
    position  : relative;
    .bannerTop_img{
        width: 100%;
        height: 100%;
    }
}
div.decorateGan {
    width: 100vw;

    div.bannerTop {
        width     : 100%;
        height    : 2.666667rem;
        position  : relative;
        margin-top: 1.373333rem !important;

        img.bannerTop_img {
            width : 100%;
            height: 100%;
        }


    }

    .decorationModuleWrap {
        margin         : .266667rem;
        display        : flex;
        flex-direction : column;
        justify-content: center;

        .decorationModuleList {
            width          : 100%;
            height         : 3.226667rem;
            background-size: 100% 100%;
            margin-bottom  : .266667rem;
            display        : flex;
            align-items    : center;

            .decorationModule_left {
                width          : 2.72rem;
                height         : 100%;
                background     : rgba(1, 102, 179, 0.69);
                color          : #fff;
                display        : flex;
                justify-content: center;
                align-items    : center;
                font-size      : .48rem;
            }

            .decorationModule_right {
                display        : flex;
                flex           : 1;
                justify-content: space-around;

                .decorationModule_label {
                    color          : #fff;
                    font-size      : .42rem;
                    height         : 100%;
                    border-right   : .026667rem dotted #fff;
                    flex           : 1;
                    display        : flex;
                    justify-content: center;
                    align-items    : center;
                    word-wrap      : break-word;
                    word-break     : break-all;
                }
            }
        }
    }
}



.decorationModuleList {
    width: 100%;

    // margin-top: .8rem;
    // 
    .moduleList_Header {
        display        : flex;
        align-items    : center;
        width          : 100%;
        justify-content: space-between;
        padding        : .106667rem .32rem .106667rem .266667rem;

        .moduleList_Header_tit {
            font-size   : .48rem;
            font-family : PingFangSC-Medium, PingFang SC;
            font-weight : 500;
            color       : #283B4D;
            line-height : .666667rem;
            margin-right: .266667rem;
        }

        .DetailTj {
            width: 100%;
        }

        .newsLists {
            width      : 100%;
            padding-top: .8rem;
        }

        .newsList_tops {
            width      : 68% !important;
            display    : flex;
            padding-top: .213333rem;
            height     : 2.826667rem;
        }

        .moduleList_Header_desc {
            font-size  : .32rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color      : #979797;
            line-height: .453333rem;
            flex       : 1;
        }

        .moduleList_Header_more {
            font-size   : .32rem;
            font-family : PingFangSC-Regular, PingFang SC;
            font-weight : 400;
            color       : #979797;
            line-height : .453333rem;
            display     : flex;
            align-items : center;
            justify-self: flex-end;

            .seeMore {
                margin-right: .213333rem;
            }

            .icon_more {
                width : .48rem;
                height: .48rem;
            }
        }
    }
}


.moduleListContentWrap {
    background   : #FFFFFF;
    box-shadow   : 0rem .133333rem .373333rem -0.106667rem rgba(212, 212, 212, 0.5);
    border-radius: .106667rem;


}

//干货列表
.decorationList_container {
    width : 100%;
    height: 100%;
}

.container_content_ {
    width         : 100%;
    height        : 100%;
    display       : flex;
    flex-direction: row;
}

.container_content_left {
    width         : 24%;
    height        : 100%;
    display       : flex;
    flex-direction: column;
    font-size     : .373333rem;
    font-family   : PingFangSC-Regular, PingFang SC;
    font-weight   : 400;
    color         : #3E3E3F;
    overflow-y    : hidden;
    background: #F6F6F6;
    /* line-height: .533333rem; */
}

.container_content_right {
    width   : 100%;
    height  : 100%;
    overflow: scroll;
}

.tabs {
    width     : 100%;
    background: rgba(255, 255, 255, 0);
    // margin-top: .266667rem;
}

#tabsID {
    // width      : 2.08rem;
    height     : 1.146667rem;
    background : #F6F6F6;
    text-align : center;
    line-height: 1.146667rem;
}

#activeId {
    // width      : 2.08rem;
    height     : 1.146667rem;
    background : #FFFFFF;
    text-align : center;
    line-height: 1.146667rem;
}

.decorationModuleLists {
    width: 100%;
    padding-bottom: 1.2rem;

}

.moduleListContent_desc_txts {
    width    : 100%;
    color    : #666;
    font-size: .32rem;
}


.moduleListContent {
    padding      : .426667rem .2333rem .106667rem;
    display      : flex;
    border-bottom: .026667rem solid #E7E7E7;

    .moduleListContent_left {
        display       : flex;
        flex-direction: column;
        flex          : 1;

        .moduleListContent_title {
            font-size         : .373333rem;
            font-family       : PingFangSC-Medium, PingFang SC;
            font-weight       : 500;
            color             : #3E3E3F;
            padding-bottom    : .106667rem;
            display           : -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            text-overflow     : ellipsis;
            overflow          : hidden;
        }

        .moduleListContent_desc {
            padding-bottom: .106667rem;

            .moduleListContent_desc_txt {

                // font-size         : .32rem;
                // font-family       : PingFangSC-Regular, PingFang SC;
                // font-weight       : 400;
                // color             : #979797;
                // line-height       : .453333rem;
                display           : -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                text-overflow     : ellipsis;
                overflow          : hidden;

                img {
                    width: 100%;
                }
            }


            .moduleListContent_desc_more {
                color: #0185FF;
            }
        }

        .moduleListContent_bottom {
            display        : flex;
            justify-content: space-between;

            .decoration_time {
                font-size  : .32rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color      : #BBBBBB;
                line-height: .453333rem;
            }

            .decoration_views {
                font-size  : .32rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color      : #BBBBBB;
                line-height: .453333rem;
                display    : flex;
                align-items: center;

                img.view_number {
                    height      : .24rem;
                    margin-right: .24rem;
                }
            }
        }
    }

    .moduleListContent_right {
        margin-left: .266667rem;
        height     : 2.373333rem;
        overflow   : hidden;

        .moduleListContent_img {
            width        : 2.373333rem;
            height       : auto;
            border-radius: .106667rem;
        }
    }
}


.container {
    background-color: #fff;
}



// 装修大全
div.question_answer {
    div.question_answer_header {
        margin         : .213333rem .32rem;
        display        : flex;
        justify-content: center;
        align-items    : center;

        .question_answer_search {
            font-size    : .32rem;
            font-family  : PingFangSC-Regular, PingFang SC;
            font-weight  : 400;
            color        : #979797;
            flex         : 1;
            width        : 5.786667rem;
            height       : .746667rem;
            background   : #FFFFFF;
            border-radius: .373333rem;
            border       : .026667rem solid #E6E6E6;
            display      : flex;
            align-items  : center;
        }

        .ant-input {
            height       : 100%;
            border-radius: 0;
        }

        .ant-input-affix-wrapper {
            padding: .053333rem .64rem;
        }

        div.askQuestionBtn {
            width          : 3.253333rem;
            height         : .746667rem;
            background     : #FFFFFF;
            border-radius  : .346667rem;
            border         : .026667rem solid #E6E6E6;
            font-size      : .32rem;
            font-family    : PingFangSC-Regular, PingFang SC;
            font-weight    : 400;
            color          : #3E3E3F;
            line-height    : .453333rem;
            display        : flex;
            justify-content: center;
            align-items    : center;

            img.askQuestionIcon {
                width       : .426667rem;
                height      : .426667rem;
                margin-right: .213333rem;
            }

            span {}
        }
    }

    div.question_answer_tags {
        padding        : .266667rem .32rem;
        display        : flex;
        flex-wrap      : wrap;
        justify-content: start;


        .ant-tag {
            width          : 2rem;
            height         : .586667rem;
            border-radius  : .426667rem;
            background     : #F7F7F7;
            font-size      : .32rem;
            font-family    : PingFangSC-Regular, PingFang SC;
            font-weight    : 400;
            color          : #979797;
            line-height    : .453333rem;
            margin-right   : .426667rem;
            display        : flex;
            justify-content: center;
            align-items    : center;
        }


        .ant-tag-checkable-checked {
            background-color: #E6F4FF !important;
            color           : #0166B3;
        }

        .ant-tag-checkable:active {
            background-color: #E6F4FF !important;
            color           : #0166B3;
        }
    }



}

.shareDetail {
    width : .426667rem;
    height: .426667rem;
}


//问答详情

.questionDetailContent {
    display       : flex;
    flex-direction: column;
    margin        : .506667rem;
    box-sizing    : border-box;
}


.moreQuestion_box {
    padding-top     : .533333rem;
    background-color: #f7f7f7;

    .moreQuestion_header {
        padding        : 0 .293333rem;
        display        : flex;
        align-items    : center;
        justify-content: space-between;

        .moreQuestion_title {
            font-size  : .48rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color      : #283B4D;
            line-height: .666667rem;

        }

        div.seeMore_question {
            display    : flex;
            align-items: center;

            span.seeMore_text {

                font-size  : .373333rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color      : #BBBBBB;
                line-height: .533333rem;

            }

            img.rightICon {
                margin-left: .16rem;
                width      : .48rem;
                height     : .48rem;
            }
        }
    }
}

.question_DetailTimes {
    background-color: #fff;
    display         : flex;
    align-items     : center;
    padding-bottom  : .506667rem;
    justify-content : space-between;

    .question_DetailTime {
        margin-right: .4rem;
    }

    .question_DetailLiulan {
        margin-right: .72rem;

        img.liulian {}
    }

    .question_IWantAnswer {
        width          : 3.04rem;
        height         : .693333rem;
        background     : #FF8618;
        display        : flex;
        justify-content: center;
        align-items    : center;
        font-size      : .373333rem;
        font-family    : PingFangSC-Medium, PingFang SC;
        font-weight    : 500;
        color          : #FFFFFF;
    }
}




.Question_list_box {
    background-color: #fff;
    padding-bottom  : .053333rem;

    .Question_list {

        padding      : .533333rem 0;
        border-bottom: .026667rem solid #E7E7E7;

        .Question_list_header {
            display    : flex;
            align-items: center;
            width      : 100%;

            .question_avator {
                width        : .613333rem;
                height       : .613333rem;
                border-radius: 50%;
                margin-right : .266667rem;
            }

            .question_name {
                font-size  : .32rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color      : #BBBBBB;
                line-height: .453333rem;

            }

            .question_time {
                flex       : 1;
                font-size  : .266667rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color      : #BBBBBB;
                line-height: .373333rem;
                text-align : right;
            }
        }

        p.Question_Desc {
            font-size  : .373333rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color      : #3E3E3F;
            line-height: .533333rem;
            padding    : .266667rem 0;
        }

        .giveThumbsUp {
            display    : flex;
            align-items: center;

            .giveThumbsUp_left {
                display     : flex;
                margin-right: .293333rem;
                align-self  : center;

                .question_dianzan {
                    margin-right: .053333rem !important;
                    margin-left : 0 !important;
                    font-size   : .456667rem !important;
                }

                .question_dianzan_active {
                    color: #108ee9;
                }

                .question_dianzan_no {
                    color: #000;
                }

                .giveThumbsUp_text {
                    font-size  : .266667rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color      : #979797;
                }

                .giveThumbsUp_text_active {
                    color: #108ee9;
                }

                .giveThumbsUp_text_no {
                    color: #000;
                }
            }

            .giveThumbsUp_right {
                display    : flex;
                align-items: center;

                .question_dianzan {
                    margin-right: .053333rem !important;
                    margin-left : 0 !important;
                    font-size   : .456667rem !important;
                }
                .icon-chaping{
                    margin: 0 !important;
                }
                .question_dianzan_active {
                    color: #000;
                }

                .question_dianzan_no {
                    color: #000;
                }

                .giveThumbsUp_text {
                    font-size  : .266667rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color      : #979797;
                }

                .giveThumbsUp_text_active {
                    color: #000;
                }

                .giveThumbsUp_text_no {
                    color: #000;
                }
            }
        }
    }

}

.Question_list:last-child {
    border-bottom: 0;
}

.question_detail_list_Wrap {
    padding      : .213333rem .32rem .32rem .32rem;
    background   : #FFFFFF;
    border-radius: .106667rem;
    border       : .026667rem solid #E6E6E6;
    margin       : .266667rem .4rem .266667rem .24rem;

    p.questionList_title {
        padding-bottom: .133333rem;
        font-size     : .373333rem;
        font-family   : PingFangSC-Regular, PingFang SC;
        font-weight   : 400;
        color         : #3E3E3F;
        line-height   : .533333rem;

    }

    .questionList_desc_wrap {

        padding-bottom: .32rem;
        position      : relative;

        .questionList_desc {
            font-size         : .32rem;
            font-family       : PingFangSC-Regular, PingFang SC;
            font-weight       : 400;
            color             : #979797;
            line-height       : .453333rem;
            text-overflow     : ellipsis;
            overflow          : hidden;
            display           : -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            padding-right     : .533333rem;
        }

        .questionList_desc_more {
            color   : #0185FF;
            position: absolute;
            right   : 0;
            top     : .373333rem;
        }

    }

    div.questionList_Bottom {
        display        : flex;
        align-items    : center;
        font-size      : .32rem;
        font-family    : PingFangSC-Regular, PingFang SC;
        font-weight    : 400;
        color          : #979797;
        line-height    : .453333rem;
        justify-content: space-between;

        span.question_time {}

        span.question_number {
            span {}
        }

        div.question_view {
            display    : flex;
            align-items: center;

            img.view_number {
                height      : .24rem;
                margin-right: .24rem;
            }
        }

        div.want_answer_btn {
            width          : 2.64rem;
            height         : .64rem;
            background     : #FFFFFF;
            border-radius  : .32rem;
            border         : .026667rem solid #10C308;
            display        : flex;
            align-items    : center;
            justify-content: center;
            font-size      : .373333rem;
            font-family    : PingFangSC-Regular, PingFang SC;
            font-weight    : 400;
            color          : #01B315;
            line-height    : .533333rem;
        }
    }
}

.questionListWrap {
    padding      : .213333rem .32rem .32rem .32rem;
    background   : #FFFFFF;
    border-radius: .106667rem;
    border       : .026667rem solid #E6E6E6;
    margin       : .266667rem .32rem;

    p.questionList_title {
        padding-bottom: .133333rem;
        font-size     : .373333rem;
        font-family   : PingFangSC-Regular, PingFang SC;
        font-weight   : 400;
        color         : #3E3E3F;
        line-height   : .533333rem;

    }

    .questionList_desc_wrap {

        padding-bottom: .32rem;
        position      : relative;

        .questionList_desc {
            font-size         : .32rem;
            font-family       : PingFangSC-Regular, PingFang SC;
            font-weight       : 400;
            color             : #979797;
            line-height       : .453333rem;
            text-overflow     : ellipsis;
            overflow          : hidden;
            display           : -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            padding-right     : .533333rem;
        }

        .questionList_desc_more {
            color   : #0185FF;
            position: absolute;
            right   : 0;
            top     : .373333rem;
        }

    }

    div.questionList_Bottom {
        display        : flex;
        align-items    : center;
        font-size      : .32rem;
        font-family    : PingFangSC-Regular, PingFang SC;
        font-weight    : 400;
        color          : #979797;
        line-height    : .453333rem;
        justify-content: space-between;

        span.question_time {}

        span.question_number {
            span {}
        }

        div.question_view {
            display    : flex;
            align-items: center;

            img.view_number {
                height      : .24rem;
                margin-right: .24rem;
            }
        }

        div.want_answer_btn {
            width          : 2.64rem;
            height         : .64rem;
            background     : #FFFFFF;
            border-radius  : .32rem;
            border         : .026667rem solid #10C308;
            display        : flex;
            align-items    : center;
            justify-content: center;
            font-size      : .373333rem;
            font-family    : PingFangSC-Regular, PingFang SC;
            font-weight    : 400;
            color          : #01B315;
            line-height    : .533333rem;
        }
    }
}




.questionTop {
    margin    : 1.466667rem .533333rem 0;
    position  : relative;
    height    : 1.733333rem;
    box-sizing: border-box;

    .bannerTop_img {
        width : 100%;
        height: 100%;
    }

    .preciseSolution {
        font-size  : .32rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color      : #FFFFFF;
        line-height: .453333rem;
        margin-top : 1.06667rem;
        z-index    : 10;
        position   : absolute;
        right      : .96rem;
    }
}
.DetailContentSeoTitle{
    width: 100%;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    overflow-x: scroll;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #979797;
  
    #seoTitle{
        width: 75px!important;
        height: 22px;
        background: #F7F7F7;
        border-radius: 16px;
        text-align: center;
        margin-right: 10px;
    }
} 
