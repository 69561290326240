.TheTopDesign {
    display       : flex;
    flex-direction: column;
    align-items   : center;
}

.button1,
.button2,
.button3,
.button4,
.button5 {
    width : 6.933333rem;
    height: 1.04rem;
}

.button1 {
    margin-top   : .8rem;
    margin-bottom: .8rem;
}

.button2 {
    margin-top   : .906667rem;
    margin-bottom: .8rem;
}

.button3 {
    margin-top   : .64rem;
    margin-bottom: 1.28rem;
}

.button4 {
    margin-bottom   : .906667rem;
    z-index         : 99;
    position        : absolute;
    right           : 16%;
    top             : 85%;
    width           : 6.933333rem;
    height          : 1.04rem;
    background-color: #000;
    color           : rgb(190, 190, 190);
    border-radius   : .8rem;
    display         : flex;
    justify-content : center;
    align-items     : center;
    font-size       : .453333rem;
}

.button5 {
    margin-top   : .906667rem;
    margin-bottom: .906667rem;


}

.BannerBox {
    width: 100vw;
}

.am-wingblank.am-wingblank-lg {
    margin-left : 0;
    margin-right: 0;
}

.am-wingblank {
    margin-left : 0;
    margin-right: 0;
}

.my-carousel {
    height: 9.04rem !important;

    .slider-frame {
        height: 9.04rem !important;
    }
}

.floorImg1 {
    width : 100vw;
    height: 9.04rem;
}

.floorImg2 {
    width : 100vw;
    height: 6.8rem;
}

.floorImg3 {
    width        : 7.626667rem;
    height       : 1.626667rem;
    margin-bottom: .666667rem;
}

.floorImg4 {
    width : 100vw;
    height: 4.24rem;
}

.floorImg5 {
    width        : 8.053333rem;
    height       : 1.706667rem;
    margin-bottom: .586667rem;
}

.floorImg6,
.floorImg7,
.floorImg8,
.floorImg9,
.floorImg10 {
    margin: .106667rem .32rem;
    width : 100%;
    height: 2.293333rem;
}

.floorImg11 {
    width : 8.213333rem;
    height: 1.706667rem;
}

.floorImg12 {

    width : 100%;
    height: 5.12rem;
    margin: .693333rem .32rem .853333rem .32rem;
}

.floor13 {
    position: relative;

}

.floorImg13 {
    width : 100vw;
    height: 12.133333rem;
}

.floor14 {
    margin-top   : .8rem;
    margin-bottom: .533333rem;
    display      : flex;
    width        : 100%;
}

.floorImg14 {
    width      : 7.626667rem;
    height     : 1.706667rem;
    margin-left: .266667rem;

}

.floorImg19 {
    width     : 1.04rem;
    height    : 1.786667rem;
    align-self: start;
}

.floorImg20 {
    margin: .48rem .133333rem .293333rem .133333rem;
    height: 6.106667rem;
}

.floor21 {
    width          : 100vw;
    margin-top     : .8rem;
    margin-bottom  : .533333rem;
    display        : flex;
    // justify-content: center;
}

.floorImg21 {
    width : 1.04rem;
    height: 1.786667rem;

}

.floorImg22 {
    width       : 6.826667rem;
    height      : 1.706667rem;
    margin-left: 1.7rem;
}

.FivefunctionArea {
    position: relative;
    height  : 3.493333rem;
    margin  : .533333rem .32rem .266667rem .32rem;

    .floorImg23 {
        height: 100%;
        width : 100%;
    }

    .functionArea {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        position: absolute;
        margin-top: -2.5rem;
        .functionAreaList {
            width        : .453333rem;
            height       : 1.6rem;
            background   : rgba(255, 255, 255, 0.3);
            border-radius: .24rem;
            font-size    : .293333rem;
            font-family  : Source Han Sans CN;
            font-weight  : 400;
            color        : #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
    }
}



.floorImg24 {
    margin: .266667rem .32rem 0 .32rem;
    height: 3.413333rem;
}

.floorImg25 {
    width : 8.506667rem;
    height: 1.253333rem;

}

.floorImg26 {
    margin: .533333rem .32rem .853333rem .32rem;
    height: 4.72rem;

}

.floorImg27 {
    width : 5.333333rem;
    height: 1.226667rem;
}

.designInput {
    margin-top    : .586667rem;
    padding       : 0 .586667rem;
    width         : 100%;
    display       : flex;
    flex-direction: column;

    .designInput_top {
        display        : flex;
        justify-content: center;

        .inputName {
            width        : 4.32rem;
            height       : .933333rem;
            background   : #FFFFFF;
            border       : .026667rem solid #CCCCCC;
            border-radius: .053333rem;
            margin-right : .16rem;
        }

        .inputArea {
            width        : 4.32rem;
            height       : .933333rem;
            background   : #FFFFFF;
            border       : .026667rem solid #CCCCCC;
            border-radius: .053333rem;

        }

    }

    .square {
        position   : absolute;
        right      : .8rem;
        margin-top : .266667rem;
        font-size  : .293333rem;
        font-family: Source Han Sans CN;

        color: #666666;
    }

    .inputPhone {
        margin-top   : .24rem;
        width        : 100%;
        height       : .933333rem;
        background   : #FFFFFF;
        border       : .026667rem solid #CCCCCC;
        border-radius: .053333rem;

    }

    input {
        padding: .16rem;
    }

    input::-webkit-input-placeholder {
        font-size  : .293333rem;
        font-family: Source Han Sans CN;

        color: #666666;

    }

    input::-moz-placeholder {
        font-size  : .293333rem;
        font-family: Source Han Sans CN;
        color      : #666666;
    }

    input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        font-size  : .293333rem;
        font-family: Source Han Sans CN;
        color      : #666666;

    }

    input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-size  : .293333rem;
        font-family: Source Han Sans CN;
        color      : #666666;

    }
}


.copyrightWrap {
    display        : flex;
    justify-content: center;
    align-items    : center;
    padding-bottom  : 1.33333rem;
    p {
        width      : 6.933333rem;
        text-align : center;
        font-size  : .24rem;
        font-family: Source Han Sans CN;
        color      : #999999;
        line-height: .453333rem;
    }
}