
.container{
    overflow-x: hidden;
}
.container_banner{
    width: 10rem;
    height: 7.253333rem;
}
.container_content{
    width: 100%;
}