#root {
    // position: relative !important;
}


.headerImg {
    width : 100vw;
    height: 2.7rem; 
}

.newsList {
    padding: .373333rem .32rem .586667rem .32rem;
    /* display   : flex; */
    border-bottom: .026667rem solid #DEDEDE;
    
}

.newsList_top {
    display: flex;
    padding-top: .213333rem;
    height: 2.826667rem;
}

.news_info   {
    width: 7.38667rem;
    font-size: .32rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #3E3E3F;
    line-height: .533333rem;
    display: -webkit-box;
    -webkit-box-orient: vertical; 
    -webkit-line-clamp: 4;

}
.decorateDetailBanner{
    border-radius: 0!important;
}
.imgUrl {
    width: 2.613333rem;
    height: 2.613333rem;  
    border-radius: .133333rem;

}
.news_title{
    width: 7rem;
    font-size: .373333rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #3E3E3F;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    margin-bottom: .3rem;
}
.news_infos { 
    height: 65%;
    width: 95%; 
}
.infos{
    width: 95%; 
    overflow: hidden;
    font-size: .32rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #979797;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    position: relative;
    &::after{
        background-color: #fff;
        padding: 0 .15rem;
        position: absolute;
        right: 0;
        top: 1.12rem;
        content: '...更多';
        color: #1890FF;
    }
}
.CaseList_info {
    font-size      : .32rem;
    font-family    : PingFangSC-Regular, PingFang SC;
    font-weight    : 400;
    color          : #979797;
    line-height    : .453333rem;
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    padding-top: .266667rem;
    padding-bottom: .266667rem;
   
}
.titleCo{
    text-align: center;
    padding-top: .266667rem;
}
.richTextWrap {
    padding: .32rem .32rem .32rem .32rem;
    width : 100%;
    height: auto;
    img{
        width: 100%;
        
    }
}
.whoSeenIt {
    display    : flex;
    align-items: center;
    padding-right: 2.6rem;
    img.seenEye { 
        width      : .4rem;
        height      : .213333rem;
        margin-right: .186667rem;
    }
    margin-right: .4rem;
}
.phone_icon {
    width: .48rem;
    height: .48rem;
}

.phone {
    font-size: .32rem;    

}

.store_btns {
    display: flex;
    align-items: center;
}

.lookHouse {
    width: 2.773333rem;
    height: .64rem;
    background: linear-gradient(180deg, #FFBB35 0%, #FF6018 100%);
    border-radius: .32rem;

    font-size: .373333rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: .533333rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.subscribeDesign {
    width: 2.773333rem;
    height: .64rem;
    background: linear-gradient(180deg, #02BBFF 0%, #025CAF 100%);
    border-radius: .32rem;
    font-size: .373333rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: .533333rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: .266667rem;
}

.store_addresss {
    font-size: .32rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3E3E3F;
    line-height: .533333rem;
    display: flex;
    align-items: center;
    // height: .586667rem;
    margin-top: .266667rem;

}

.store_addresss img {
    width: .426667rem;
    height: .426667rem;
    margin-right: .266667rem;

}

// 底部栏
.Footer {
    // position: fixed;
    bottom: 1.6rem;
    width         : 100vw;
    height        : 2.72rem;
    background    : #3E3E3F;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: .32rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #F7F7F7;
    line-height: .453333rem;

    .consulting {
        display: flex;

        .phoneNum {
            margin-left: .48rem;
        }

        .phone_icon {
            width: .453333rem;
            height: .453333rem;
            margin-left: .266667rem;
        }
    }

    .address {
        padding-top: .426667rem;
    }
}