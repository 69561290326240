.Install_innings {
    background-color: #fff;
    display         : flex;
    justify-content : center;
    align-items     : center;
    flex-direction  : column;

    .Install_innings_logo {
        width          : 3.36rem;
        margin-top     : .533333rem;
        display        : flex;
        flex-direction : column;
        align-items    : center;
        justify-content: center;

        .Install_innings_logoTop {
            width : 3.36rem;
            height: .373333rem;
        }

        .Install_innings_logoBottom {
            width : 3.36rem;
            height: .266667rem;
        }
    }

    .Install_innings_header {
        width           : 100%;
        height: 1.813333rem;
        background-color: #f8f8f8;
        display: flex;
        justify-content: center;
        align-items: center;
        p.Install_innings_title {
            font-size  : .426667rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color      : #000000;
            line-height:  1.813333rem;
            text-align: center;
        }
    }



    .Install_innings_content {
        width: 100%;
        ul.Install_innings_list {
            li.Install_innings_item {
                position     : relative;
                width        : 100%!important;
                margin-bottom: .266667rem!important;

                .innings_bg {
                    width           : 100vw;
                    height          : 4rem;
                    background-color: #ccc;
                }

                .innings_info {
                    width          : 100%!important;
                    height         : 100%;
                    margin-top     : -4rem;
                    position       : absolute;
                    display        : flex;
                    flex-direction : column;
                    align-items    : center;
                    color          : #FFFFFF;
                    justify-content: center;

                    .innings_title {
                        font-size  : .64rem;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        line-height: 1.12rem;
                    }

                    .innings_desc {
                        font-size  : .32rem;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        line-height: .693333rem;

                    }

                    .innings_detail {
                        font-size  : .373333rem;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        line-height: .533333rem;

                    }
                }
            }
        }
    }
}