.MyStep {
    display    : flex;
    align-items: center;

    .stepList {
        position: relative;

        .stepNum {
            font-size       : .48rem;
            font-family     : PingFangSC-Semibold, PingFang SC;
            font-weight     : 600;
            color           : #FFFFFF;
            line-height     : .666667rem;
            background-color: #005BAB;
            border          : .026667rem solid #005BAB;
            width           : .8rem;
            height          : .8rem;
            display         : flex;
            justify-content : center;
            align-items     : center;
            border-radius   : 50%;
            z-index         : 100;
            margin: 0 1.066667rem 0.3rem  0.7rem;
        }

        .stepImg {
            top     : .32rem;
            position: absolute;
            width   : .933333rem;
            left    : .533333rem;
            z-index : 99;
            margin-left: 0.7rem;
        }

        .stepLine {
            background-color: #F2F2F2;
            top             : .32rem;
            height          : .213333rem;
            width           : 80%;
            position        : absolute;
            margin-left     :1.5rem;
        }

        .stepTitle {
            font-size  : .373333rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color      : #000000;
            line-height: .533333rem;
            margin-left: .213333rem;
            word-break:keep-all;
        }
    }
}