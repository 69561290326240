.container {
    background-color: #F7F7F7;
}

.actList {
    // margin-top: 1.2rem;
}
.loadMore{
    background-color: #F7F7F7;
text-align: center;
height: 1rem;
line-height: 1rem;
}
.actListWrap {
    padding-bottom: .266667rem;
    width         : 100%;

    .actList_item {
        background-color: #fff;
        margin          : .266667rem .266667rem 0 .266667rem;
        border-radius   : .106667rem;
        overflow        : hidden;

        img.item_img {
            width : 100%;
            height: 4.16rem;
            margin: 0 auto;
        }

        hgroup.item_title {
            display: flex;
            justify-content: space-between;
        }

        span.item_name {
            font-size         : .373333rem;
            font-family       : PingFangSC-Regular, PingFang SC;
            font-weight       : 400;
            color             : #3E3E3F;
            line-height       : .533333rem;
            height            : .533333rem;
            display           : -webkit-box;
            overflow          : hidden;
            text-overflow     : ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            margin            : .133333rem .266667rem;

        }

        .item_info {
            font-size      : .32rem;
            font-family    : PingFangSC-Regular, PingFang SC;
            font-weight    : 400;
            color          : #979797;
            line-height    : .453333rem;
            display        : flex;
            align-items    : center;
            justify-content: space-between;
            padding        : 0 .266667rem .4rem .266667rem;

            .whoSeenIt {
                display    : flex;
                align-items: center;
                padding-right: 0;
                img.seenEye {
                    // width       : .32rem;
                    height      : .32rem;
                    margin-right: .186667rem;
                }
            }
        }
    }
}



.filterWrap .filterWrap_list .filterWrap_list_wrap {
    float         : left;
    width         : 33.3333% !important;
    display       : flex;
    flex-direction: column;
    align-items   : center;
}


.filter_icon_more {
    width      : .426667rem;
    height     : .426667rem;
    margin-left: .053333rem;
}