.container {
    background: #F5F5F5;
}

.House_quickly {
    .bannerr {
        // height: 6.666667rem;
        width: 10rem !important;
        //margin-top: 1.35rem;
    }

    .slider-slide img {
        width: 100% !important;
        // height: 300px !important;
        object-fit: cover;
    }
}

.slider-frame {
    height: auto !important;
}

.slider {
    display: none;
}

// .slider-list{
//     height: auto;
// }
.DividingLine {
    width: 100%;
    height: .16rem;
    background: #F5F5F5;
}

.SetMeal {
    padding: 0 .32rem .586667rem .32rem;
    background-color: #fff;

    .SetMealInfo {
        .SetMealInfo_top {
            display: flex;
            align-items: center;
            margin-bottom: 0.2rem;
            margin-top: 0.2rem;

            .SetMealPirce {
                span.price {
                    font-size: .533333rem;
                    font-family: Lantinghei SC;
                    font-weight: 600;
                    color: #E93340;
                    line-height: .773333rem;

                }

                span.Company {
                    font-size: .293333rem;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    line-height: .773333rem;

                    sup {}
                }
            }

            .remarks {
                font-size: .266667rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #E93340;
                line-height: .773333rem;
                flex: 1;
                display: flex;
                justify-content: center;
                margin-bottom: 0.15rem;

                p {
                    background: #fceced;
                    width: 190px;
                    height: 22x;
                    line-height: 22px;
                    text-align: center;
                }
            }

            .SetMealBtns {
                font-size: .266667rem;
                font-family: Source Han Sans CN;
                color: #666666;
                line-height: .773333rem;
                display: flex;
                align-items: center;

                .EventNotice {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .noticeIcon {
                        width: .453333rem;
                        height: .453333rem;
                    }

                    span {}
                }

                .share {
                    //display       : flex;
                    flex-direction: column;
                    align-items: center;
                    margin-left: .16rem;

                    .share {
                        width: .453333rem;
                        height: .453333rem;
                    }

                    span {}
                }
            }
        }

        .SetMealInfo_bottom {
            position: relative;

            span.SetMealInfo_title {
                font-size: .4rem;
                font-family: Lantinghei SC;
                font-weight: 600;
                color: #333333;
                line-height: .773333rem;
                padding-bottom: .693333rem;
            }

            .appointment_popularity {
                display: flex;
                position: relative;
                font-size: .32rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #999999;
                line-height: .773333rem;

                .appointmentsNumber {

                    span {
                        color: #E93340;
                    }
                }

                .popularity {
                    display: flex;
                    align-items: center;
                    margin-left: .533333rem;

                    span {
                        margin-right: .106667rem;
                    }

                    img {
                        width: .346667rem;
                        height: .32rem;
                        margin-top: -0.053333rem;
                        margin-right: .053333rem;
                    }
                }
            }

            .ask {
                width: 2.186667rem;
                height: .693333rem;
                background: #EB5D2A;
                border-radius: .08rem;
                font-size: .32rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: .773333rem;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: .32rem;
                margin-top: -1.066667rem;
            }
        }

        ul.setMealList {
            padding: 0 .053333rem;

            li.setMealItem {
                height: 1.226667rem;
                display: flex;
                align-items: center;
                width: 100% !important;
                font-size: .346667rem;

                .listIcon {
                    width: .346667rem;
                    height: .346667rem;
                    margin-right: .213333rem;
                }

                a {
                    font-size: .346667rem;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #333335;
                    line-height: 1.226667rem;
                    flex: 1;
                }

                span {
                    font-size: .346667rem;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #333335;
                    line-height: 1.226667rem;
                    flex: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

             
            }
        }
    }
}
   .rightIcon {
       width: .186667rem;
       height: .32rem;
   }
.dividing {
    width: 100%;
    border-bottom: 2px dotted #ccc;
    // height: 2px;
}

sup {
    font-size: .266667rem;
}

// 业主评价

.evaluateWrap {
    margin: .16rem 0;
    background-color: #fff;
    padding: .533333rem .32rem;

    .evaluate_title {
        font-size: .373333rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        line-height: .773333rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            font-size: 0.5rem;
            font-family: Source Han Sans CN;
            font-weight: 600;
            color: #333333;
        }

        .lookAll {
            font-size: .293333rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #E93340;
            line-height: .773333rem;
        }
    }

    .evaluateList {
        .evaluateList_header {
            padding: .533333rem .213333rem 0 .213333rem;
            display: flex;
            align-items: center;

            .avator {
                width: .986667rem;
                height: .986667rem;
                border-radius: 50%;
            }

            .evaluateList_Info {
                margin-left: .213333rem;

                .evaluate_name {
                    display: block;
                    height: .8rem;
                    line-height: .8rem;
                    width: 4rem;
                    overflow: hidden;
                    font-size: .32rem;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #222222;
                    line-height: .773333rem;
                     overflow: hidden;
                     text-overflow: ellipsis;
                     white-space: nowrap;

                }

                .evaluate_time {
                    display: flex;
                    align-items: center;
                    font-size: .266667rem;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #999999;

                    .evaluate_time_txt {
                        color: #999999;
                    }

                    .releaseEvaluation_txt {
                        margin-left: .266667rem;
                        color: #222222;
                    }
                }
            }
        }

        .evaluateList_content {
margin: .32rem 0;
            overflow: hidden;
            font-size: .293333rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: .426667rem;
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            padding: 0rem .266667rem;
        }

        .evaluateList_footer {
            padding: 0 .266667rem;
            font-size: .266667rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            line-height: .533333rem;
        }
    }
}


// 店铺介绍

.shopIntroduction {
    background-color: #fff;

    .shopIntroduction_header {
        display: flex;
        align-items: center;
        padding: .426667rem .32rem .426667rem .426667rem;
        justify-content: space-between;

        .shopIntroduction_Info {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .shopLogo {
                width: 3.226667rem;
                height: .48rem;
            }

            .shopDesc {
                font-size: .266667rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #666666;
                line-height: .533333rem;
            }
        }

        .shopIntroduction_btns {
            display: flex;
            align-items: center;

            .allServices,
            .aboutUs {
                width: 1.76rem;
                height: .506667rem;
                border: .026667rem solid #E93340;
                border-radius: .266667rem;
                font-size: .293333rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #EA3E49 !important;
                line-height: .533333rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .allServices {}

            .aboutUs {
                margin-left: .213333rem;
            }
        }
    }

    .shopIntroduction_desc {
        padding: 0 .32rem;
        display: flex;
        align-items: center;
        font-size: .266667rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        line-height: .32rem;
        justify-content: space-evenly;

        .shopIntroduction_desc_list {
            display: flex;
            align-items: center;

            .pinpai,
            .shouyexiugai,
            .xianhangpeifu {
                width: .32rem;
                height: .32rem;
                margin-right: .106667rem;
            }

            .shopIntroduction_desc_txt {}
        }
    }

    .shop_classic_cases {
        padding: .586667rem .32rem .533333rem .32rem;

        .classic_cases_header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: .266667rem;

            .classic_cases_title {
                font-size: .5rem;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #333333 !important;
                line-height: .773333rem;

            }

            .lookAll {
                font-size: .293333rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #E93340;
                line-height: .773333rem;
            }
        }

        ul.classic_cases_content {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-columns: 1fr 1fr;
            grid-gap: .16rem;

            li.classic_cases_content_list {
                width: 100%;
                height: 2.853333rem;
                position: relative;
                width: 100%;

                img.classic_cases_img {
                    width: 100%;
                    height: 2.853333rem;
                }

                .classic_cases_txt {
                    position: absolute;
                    margin-top: -0.586667rem;
                    text-align: center;
                    width: 3.44rem;
                    height: .32rem;
                    font-size: .32rem;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: .586667rem;
                    width: 4.613333rem;
                    height: .586667rem;
                    background: rgba(0, 0, 0, 0.65);
                }
            }
        }

        .slide_see_details {
            font-size: .266667rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            line-height: .773333rem;
            padding-bottom: .533333rem;
            text-align: center;
        }
    }
}

.slide_see_details {
    font-size: .266667rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: .773333rem;
    padding-bottom: .533333rem;
    text-align: center;
}

.gaphicDetails {
    .gaphicDetailsImg {
        width: 100vw;
    }
}

.app_footer_content {
    // display: none;
}

.bottomBtns {
    height: 1.86rem;
    position: fixed;
    display: flex;
    align-items: center;
    bottom: 0;
    z-index: 9999;
    background: #fff;
    width: 100vw;
    border: 1px solid #F5F5F5;

    .bottom_Btn {
        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: .266667rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        line-height: .8rem;
        height: 100%;
        padding-top: 0.4rem;
        border-right: 1px solid #F5F5F5;

        .bottom_Btn_icon1 {
            width: .533333rem;
            height: .506667rem;
        }

        .bottom_Btn_icon2 {
            width: .586667rem;
            height: .506667rem;
        }

        .bottom_Btn_icon3 {
            width: .48rem;
            height: .506667rem;
        }
    }

    .getQuote,
    .nowSubscribe {
        width: 2.906667rem;
        height: 1.86rem;
        font-size: .346667rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 1.013333rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .getQuote {
        background: #F09938;
    }

    .nowSubscribe {
        background: #E93340;
    }
}

.BookingRoomBtn {
    width: 8.293333rem;
    height: 1.12rem;
    background: linear-gradient(0deg, #F16C00, #F4881F);
    font-size: .373333rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notice {
    margin-bottom: 1.093333rem !important;
    margin-top: .6rem;
}

.ClickBanner {
    width: 100%;
    font-size: .373333rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
    }
}

.gaphicDetails_title {
    font-size: .533333rem;
    font-weight: 500;
    color: #333333;
    font-family: Source Han Sans CN;
    text-align: center;

    // width: 6.933333rem;
    img {
        width: 100%;
    }
}



.border_solid {
    width: 90%;
    height: 1px;
    border-bottom: .05rem solid #E2E2E2;
    margin-top: .7rem;
    margin-left: 5%;
}

.am-carousel-wrap {
    display: none !important;
}

.detailBa {
    .slider-list {
        //  height: 200px!important;
    }
}

.Carousels {
    .slider-list {
        height: 7.1rem !important;
    }
}