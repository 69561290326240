.container {
    background-color: #f7f7f7;
}

.TheConstructionSiteLive {
    overflow  : hidden;
    //margin-top: 1.4rem;
}
.SiteLive_headimg_box{
    height: 2.59rem;
    overflow: hidden;
    position: relative;
    .SiteLive_headimg {
        display: block;
        width: 100%;
        height: auto;
        top: -58.1731%;
        left: 0px;
        position: absolute;
    }
    
}

.downloadApp {
    background-color: #f7f7f7;
    display: flex;
    justify-content: center;
    padding-bottom: 1.653333rem;
    padding-top: .533333rem;
    .downloadImg{
        width : 9.466667rem;
        height: 1.6rem;
      
    }
   
}

.SiteLiveInfo {
    padding         : .266667rem .666667rem .48rem .666667rem;
    background-color: #fff;
    position        : relative;
    margin-bottom   : .4rem;
    background-color: #fff;

    .SiteLiveHeader {
        display        : flex;
        align-items    : center;
        justify-content: space-between;
        padding-bottom : .266667rem;

        .siteLive_name {
            font-size  : .426667rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color      : #000000;
            line-height: .586667rem;
        }

        .siteLiveAddress {
            display    : flex;
            align-items: center;

            img.addressIcon {
                width : .293333rem;
                height: .426667rem;
                margin-right: .106667rem;
            }

            .siteLive_address {
                font-size  : .373333rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color      : #000000;
                line-height: .533333rem;

            }
        }
    }

    p.site_houseInfo {
        font-size     : .373333rem;
        font-family   : PingFangSC-Semibold, PingFang SC;
        font-weight   : 600;
        color         : #000000;
        line-height   : .533333rem;
        padding-bottom: .266667rem;
    }

    p.site_startTime {
        line-height: .8rem;
        font-size  : .373333rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color      : #000000;
    }

    .subScribevisit {
        background-color: #FF6801;
        width           : 2.666667rem;
        border-radius   : .4rem;
        height          : .8rem;
        display         : flex;
        justify-content : center;
        align-items     : center;
        font-size       : .373333rem;
        font-family     : PingFangSC-Semibold, PingFang SC;
        font-weight     : 600;
        color           : #FFFFFF;
        line-height     : .533333rem;

    }
}
.Placeholder_boxT{
    width: 100%;
    height: .4rem;
    background: #F7F7F7;
}
.sectionTitles {
    font-size: .426667rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #332d2d;
    line-height  : .586667rem;
    margin-bottom: .64rem;
}

.managementTeam {
    padding         : .4rem .666667rem;
    background-color: #fff;
    .managementTeamList {
        display    : flex;
        align-items: center;
        flex-direction: row;
        // justify-content: space-around;
        .managementTeamItems {
            width: 25%!important;
            display       : flex;
            flex-direction: column;
            align-items   : center;
            margin-right  : .8rem;

            .identityPhoto {
                width : 1.866667rem;
                height: 1.866667rem;
            }

            .managementTeamName {
                font-size  : .373333rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color      : #000000;
                line-height: .8rem;
            }

            .positionalTitles {

                font-size  : .32rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color      : #7E7E7E;
                line-height: .533333rem;

            }

            .workExperience {
                font-size  : .32rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color      : #7E7E7E;
                line-height: .533333rem;
                white-space: nowrap;

            }

            .managementTeamBtn {
                width           : 2.133333rem;
                height          : .666667rem;
                display         : flex;
                justify-content : center;
                align-items     : center;
                font-size       : .266667rem;
                font-family     : PingFangSC-Regular, PingFang SC;
                font-weight     : 400;
                color           : #FFFFFF;
                line-height     : .373333rem;
                background-color: #FF6801;
                border-radius   : .346667rem;
                margin-top      : .266667rem;
            }
        }
    }
}


.ConstructionProgress {
    padding         : .4rem .666667rem;
    background-color: #fff;
    margin-top      : .4rem;

    .constructionSchedule {
        padding-top: .453333rem;

        .schedule_title {
            position      : relative;
            padding-bottom: .266667rem;

            .schedule_title_img {
                width : 2.133333rem;
                height: .666667rem;
            }

            .schedule_title_txt {
                position   : absolute;
                font-size  : .373333rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color      : #fff;
                line-height: .533333rem;
                top        : .106667rem;
                left       : .4rem;
            }
        }

        .schedule_desc {
            font-size     : .373333rem;
            font-family   : PingFangSC-Semibold, PingFang SC;
            font-weight   : 600;
            color         : #000000;
            line-height   : .533333rem;
            padding-left  : .4rem;
            padding-bottom: .266667rem;

        }

        p.schedule_time {
            font-size   : .373333rem;
            font-family : PingFangSC-Regular, PingFang SC;
            font-weight : 400;
            color       : #000000;
            line-height : .533333rem;
            padding-left: .4rem;
            padding-top : .266667rem;
        }

        .Schedule_img_wrap {
            display     : flex;
            flex-wrap   : wrap;
            padding-left: .266667rem;

            img.Schedule_img {
                width : 2.666667rem;
                height: 2.666667rem;
                margin: .053333rem;
                border-radius: .106667rem;

            }
        }

        p.expandMore {
            display     : flex;
            align-items : center;
            padding-top : .4rem;
            padding-left: .4rem;

            .expandMore_txt {
                font-size  : .373333rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color      : #003CCA;
                line-height: .533333rem;
            }

            img.downArrow {
                width      : .373333rem;
                height     : .16rem;
                margin-left: .266667rem;
            }
        }
    }
}


.sameCommunityCase {
    // margin-top      : .4rem;
    background-color: #fff;
    padding         : .4rem .32rem;
    box-sizing: border-box;
    .sameCommunityCaseWrap {
        display  : flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        .sameCommunityCaseList {
            display       : flex;
            flex-direction: column;
            align-items   : center;
            img.sameCommunityCaseImg {
                width : 4.533333rem;
                height: 4.533333rem;
                margin-right: .106667rem;
                border-radius: .106667rem;
            }

            span.sameCommunityCaseTitle {
                font-size  : .373333rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color      : #000000;
                line-height: .8rem;

            }

            span.sameCommunityCaseDesc {
                font-size  : .266667rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color      : #000000;
                line-height: .373333rem;
                padding-bottom: .4rem;
            }
        }
    }

}