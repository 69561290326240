* {
  padding: 0;
  margin: 0;
}

a {
  color: #666666;
}

.FoundPanel_top_list {
  width: 100% !important;
}

.found {
  width: 100%;
  height: 100%;
}

.FoundPanel_middle_list {
  width: 100% !important;
}

.found_swiper {
  width: 100%;
}

.found_swiper div {
  width: 100%;
}

.found_swiper img {
  width: 100%;
}

.found_showbox {
  height: .533333rem;
  font-size: .266667rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  line-height: .32rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #fdfdfd;
  font-size: .293333rem;
}

.found_showbox img {
  width: .293333rem;
  height: .293333rem;
  margin-bottom: .053333rem;
  margin-right: .053333rem;
}

.fourbox_img {
  width: 1.866667rem;
  height: 1.866667rem;
}

.show_box {
  width: 100%;
}

.found_showFour {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: .346667rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  height: 2.526667rem;
}

.found_showFour a {
  color: #666666;
}

.main_fourBox {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  align-items: center;
  width: 2.586667rem;
  height: 2.586667rem;
}

.main_fourBox a {
  color: #413333 !important;
}

.Special_show img {
  width: 100%;
  height: auto;
}

.Seconds_killToday {
  /* width: 100%; */
  height: 5.973333rem;
  margin: 0 .48rem;
}

.Seconds_killToday_mao {
  width: 100%;
  height: .8rem;
  display: flex;
  flex-direction: row;
  margin-top: 0.25rem;
}

.Seconds_killToday_mao_time {
  display: flex;
  flex-direction: row;
  color: #D81E06;
}

._miaosha {
  height: .48rem;
  width: 1.92rem;
  margin-right: .293333rem;
}

.miaosha {
  width: 1.92rem;
  height: .48rem;
}

.naozhong {
  width: .346667rem;
  height: .373333rem;
  margin-top: .16rem;
  margin-right: .106667rem;
}

.over {
  margin-right: .24rem;
  width: 1.28rem;
  height: .32rem;
  font-size: .32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  margin-top: .106667rem;
}

._time {
  display: flex;
  flex-direction: row;
}

._time span {
  /* width: .453333rem;
    height: .426667rem; */
  background: #FFE8F1;
  line-height: .426667rem;
  margin-top: .133333rem;
  width: .64rem;
  height: .48rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #D81E06;
  text-align: center;
}

._time p {
  margin: .08rem .186667rem 0rem .186667rem;
}

.Seconds_killToday_kk {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 5.333333rem;
  margin-right: 0;
}

.Seconds_killToday_kk_one {
  width: 4.4rem;
  height: 5.04rem;
  margin-left: .16rem;
}

.Seconds_killToday_kk_two {
  width: 4.4rem;
  height: 5.04rem;
  margin-left: .213333rem;
}

.miaosha_one {
  width: 4.4rem;
  height: auto;
}

.miaosha_two {
  width: 4.4rem;
  height: auto;
}

.Our_Services {
  /* height: 9.066667rem; */
  margin: .613333rem .32rem 0 .32rem;
}

.Our_Services-title {
  width: 100%;
  height: 1.013333rem;
  display: flex;
  flex-direction: row;
}

.title_one {
  height: .666667rem;
  font-size: .48rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #283B4D;
  line-height: .666667rem;
}

.title_two {
  height: .666667rem;
  font-size: .32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #979797;
  line-height: .453333rem;
  margin-left: .266667rem;
  line-height: .773333rem;
}

.Our_Services_content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Our_Services_content_top {
  display: flex;
  flex-direction: row;
  margin-bottom: .213333rem;
}

.Our_Services_content_bto {
  display: flex;
  flex-direction: row;
}

.zx1 {
  width: 6.24rem;
  height: 3.84rem;
  margin-right: .16rem;
  background-size: 100%;
  background-repeat: no-repeat;
}

.zx2 {
  width: 3.013333rem;
  height: 3.84rem;
}

.zx3 {
  width: 3.04rem;
  height: 3.84rem;
  margin-right: .16rem;
}

.zx4 {
  width: 3.04rem;
  height: 3.84rem;
  margin-right: .16rem;
}

.zx5 {
  width: 3.013333rem;
  height: 3.84rem;
}

.Special_show {
  width: 100%;
  height: auto;
  margin-left: .32rem;
  padding-right: .32rem;
}

.Special_show img {
  padding-right: .32rem;
}

.Decorate_process {
  height: 5.333333rem;
  margin: .32rem;
  display: flex;
  flex-direction: column;
}

.Decorate_process_content {
  margin: .426667rem .32rem;
}

.zxlc {
  width: 100%;
  height: 3.84rem;
}

.advantage {
  width: 100%;
}

.Heart_selectedList {
  height: 4.426667rem;
  margin-top: .266667rem;
  margin-left: .32rem;
  margin-right: .32rem;
}

.zs899 {
  width: 100%;
  height: 2.853333rem;
}

.Placeholder_box {
  width: 100vw;
  height: .16rem;
  background: #F5F5F5;
  margin-bottom: .506667rem;
}

.Actually_show {
  margin: 0 .32rem;
}

.Actually_show_beijing1 {
  width: 100%;
  border: .026667rem solid #E8E8E8;
  border-bottom: 0;
}

.Actually_show_title {
  font-size: .48rem;
  font-family: Lantinghei SC;
  font-weight: 600;
  color: #333333;
  line-height: 1.733333rem;
  height: 1.733333rem;
  text-align: center;
  width: 6.4rem;
  position: absolute;
  margin-top: -1.6rem;
}

.Actually_show_top {
  height: 3.013333rem;
}

.Actually_show_text {
  width: 2.16rem;
  height: .32rem;
  font-size: .366667rem;
  font-family: FZZYJW;
  font-weight: 600;
  color: #333333;
  line-height: .32rem;
}

.Actually_show_top {
  display: flex;
  flex-direction: row;
}

.Actually_show_top_left {
  width: 4.666667rem;
  height: 3.013333rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: .026667rem solid #E8E8E8;
}

.jr_log {
  width: 3.013333rem;
  height: .906667rem;
}

.jrjzz {
  height: .666667rem;
  font-size: .346667rem;
  font-family: Regular;
  font-weight: 400;
  color: #333333;
  line-height: .666667rem;
}

.Actually_show_top_right {
  width: 4.666667rem;
  height: 3.013333rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: .026667rem solid #E8E8E8;
  border-left: 0;
}

.Actually_show_top_right_top {
  height: 1.546667rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-bottom: .026667rem solid #E8E8E8;
  padding: 0 .16rem;
}

.Actually_show_top_right_top_left {
  display: flex;
  flex-direction: column;
  width: 3.52rem;
}

.Actually_show_content_right_top_left {
  display: flex;
  flex-direction: column;
  width: 3.52rem;
}

.jzjy {
  height: .346667rem;
  font-size: .346667rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: .346667rem;
  margin: .266667rem 0;
}

.jzjyu {
  height: .32rem;
  font-size: .293333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  line-height: .106667rem;
  letter-spacing: -0.026667rem;
}

.jzz {
  width: .933333rem;
  height: .933333rem;
}

.Actually_show_content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
}

.Actually_show_content_left {
  width: 4.666667rem;
  height: 1.76rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 .16rem;
  border: .026667rem solid #E8E8E8;
  border-top: 0;
}

.Site_live {
  height: 6.4rem;
  display: flex;
  flex-direction: column;
  margin: 0 .32rem;
}

.title_three {
  width: 1.866667rem;
  font-size: .293333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  text-align: right;
  line-height: .773333rem;
  position: absolute;
  right: .32rem;
}

.Site_live_content_zb {
  margin-right: .64rem;
  height: .773333rem;
  background: #F4F4F4;
  border-radius: .4rem;
  margin-bottom: .453333rem;
  display: flex;
  flex-direction: row;
}

.Site_live_content {
  display: flex;
  flex-direction: row;
}

.Site_live_content_hg {
  overflow-x: scroll;
}

.Site_live_content_hg_img {
  width: 5.893333rem;
  height: 3.706667rem;
  margin-right: .16rem;
  position: relative;
}

.Site_live_content_hg_img .Site_live_content_mark {
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 11;
  margin-top: -0.64rem;
  width: 100%;
  height: .64rem;
  background: #000000;
  opacity: 0.65;
  padding: 0 .213333rem;
}

.Site_live_content_hg_img .Site_live_content_mark .Site_live_content_address {
  flex: 1;
  text-align: left;
  font-size: .32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  line-height: .773333rem;
}

.Site_live_content_hg_img .Site_live_content_mark .Site_live_content_see {
  font-size: .293333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #E2E2E2;
  line-height: .773333rem;
}

.Site_live_content_hg_img .Site_live_content_mark .Site_live_content_see .liulian {
  width: .293333rem;
  height: .186667rem;
  margin-right: .08rem;
}

.gdhg {
  width: 5.893333rem;
  height: 3.706667rem;
}

.Site_live_content_zb_city {
  width: 6.746667rem;
  height: .773333rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: .13rem;
}

.dizhi {
  width: .426667rem;
  height: .48rem;
  margin-right: .8rem;
  margin-left: .266667rem;
}

.Our_Servicesr_case {
  margin-bottom: .533333rem;
}

.neighbor_case {
  margin: 0 .32rem .266667rem .32rem;
}

.pingfen {
  margin-right: .266667rem;
}

.xaingjie {
  width: 4.533333rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: #999999;
}

.pingfen_xin img {
  width: .266667rem;
  height: .24rem;
  margin-right: .053333rem;
}

.tupian_ {
  display: flex;
  flex-direction: row;
  margin-top: .24rem;
  justify-content: space-around;
}

.Heart_selectedList_content {
  width: 100%;
}

.tupian_ img {
  margin-right: .266667rem;
}

.Our_Services-title_title_three {
  width: 70;
  font-size: .293333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  position: absolute;
  right: .32rem;
  line-height: .773333rem;
}

.Our_Services-title_content {
  width: 9.36rem;
  height: 5.813333rem;
  background: #999999;
}

.neighbor_case_content {
  display: flex;
  flex-direction: row;
  margin-top: .266667rem;
  border-bottom: .026667rem dashed #E2E2E2;
}

.b_l {
  margin-right: .533333rem;
  color: #999999;
}

.tabss1 {
  display: flex;
  flex-wrap: wrap;
}

.tabss1 div {
  margin-right: .46rem !important;
  margin-top: .266667rem !important;
}

.ant-tabs-top>.ant-tabs-nav {
  margin: 0 0 .266667rem 0 !important;
}

.neighbor_case_content_left {
  width: .986667rem;
  height: 100%;
  margin-right: 3%;
}

.neighbor_case_content_right {
  display: flex;
  flex-direction: column;
}

.yzzss {
  width: .986667rem;
  height: .986667rem;
  border-radius: 50%;
}

.neighbor_case_content_right_b {
  display: flex;
  flex-direction: row;
}

.neighbor_case_content_right_c {
  display: flex;
  flex-direction: row;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
  justify-content: space-around;
}

.neighbor_case_content_right_e {
  width: 8.5rem;
overflow:scroll;
  /* display: flex; */
  /* flex-direction: row; */
  padding-bottom: .533333rem;
}

.neighbor_case_content_right_c {
  margin-top: .133333rem;
}

.neighbor_case_content_right_d {
  margin-top: .266667rem;
  padding-right: .32rem;
  width: 8.5rem;
}

#tabss_t {
  background: #F4F4F4;
  width: 20%;
  font-size: .346667rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  text-align: center;
  border-radius: .32rem;
  margin-right: 3%;
  margin-top: 2%;
}

.DecorateDatesDate {
  font-size: .266667rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
}

#active {
  color: #017ADA !important;
  background: #DDEDFC;
  width: 20%;
  font-size: .346667rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  text-align: center;
  border-radius: .32rem;
  margin-right: 3%;
  margin-top: 2%;
}

.DecorateDates {
  display: flex;
  /* */
}

.DecorateContent_ {
  display: flex;
  flex-direction: row;
  border-bottom: .026667rem solid #E2E2E2;
  align-items: start;
  padding: .48rem 0 .426667rem 0;
}

.DecorateContent_:last-child {
  border-bottom: 0;
}

.DecorateTitele {
  font-size: .373333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #222222;
  line-height: .853333rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 5.6rem;
  height: 0.803333rem;
}

.liulian {
  line-height: .186667rem;
  margin-bottom: .106667rem;
}

.ant-carousel .slick-slide {
  text-align: center;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

.DecorateDatesTime {
  font-size: .293333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  margin-right: .506667rem;
}

.DecorateContent {
  margin-right: .32rem;
}

.DecorateImg {
  width: 3.76rem;
  height: 2.293333rem;
}

.content {
  width: 5.813333rem;
  height: 1.253333rem;
  margin-top: .346667rem;
}

.DecorateImgs {
  width: 100%;
  height: 100%;
  border-radius: .106667rem;
  object-fit: cover;
}

.DecorateDrycargo {
  margin: 0 .32rem 0.6rem .32rem;
}

.tupian {
  width: 2.4rem;
  height: 2.4rem;
}

.show_Footer {
  width: 100vw;
  background: #333333;
  padding-bottom: 2.186667rem;
  padding-top: .533333rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: .32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #F7F7F7 !important;
  line-height: .8rem;
  z-index: 10;
}

.show_Footer a {
  color: #F7F7F7 !important;
}

.show_Footer_ span {
  margin-left: .266667rem;
}

.phone {
  width: .586667rem;
  height: .586667rem;
  margin-right: .266667rem;
}

.phone_tel {
  font-size: .426667rem;
}

.decorate {
  margin: 0 .32rem;
  height: 11.44rem;
}

.Our_Services_opcity {
  width: 3.546667rem;
  height: 5.813333rem;
  background: #000000;
  opacity: 0.65;
  position: absolute;
  float: right;
  right: .32rem;
}

.caseCarousel {
  margin: 0 .32rem !important;
}

.caseBox {
  height: 5.813333rem;
  color: #FFFFFF;
  box-sizing: border-box;
  position: relative;
}

.caseBox .casebg {
  height: 5.813333rem;
  width: 100%;
}

.caseBox .caseInfo {
  position: absolute;
  z-index: 11;
  display: flex;
  flex-direction: column;
  width: 3.846667rem;
  height: 5.813333rem;
  background-color: rgba(0, 0, 0, 0.65);
  right: 0;
  margin-top: -5.813333rem;
  padding: .213333rem;
}

.caseBox .caseInfo .simpleInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.caseBox .caseInfo .simpleInfo .infoLeft {
  display: flex;
  flex-direction: column;
}

.caseBox .caseInfo .simpleInfo .infoLeft .designidentity {
  font-size: .24rem;
  font-family: Source Han Sans CN;
  line-height: .773333rem;
}

.caseBox .caseInfo .simpleInfo .infoLeft .designname {
  font-size: .32rem;
  font-family: Source Han Sans CN;
  line-height: .533333rem;
  text-align: left;
}

.caseBox .caseInfo .simpleInfo .headportrait {
  width: 1.28rem;
  height: 1.2rem;
  border-radius: 50%;
  padding-right: 0.05rem;
}

.caseBox .caseInfo .designdesc {
  display: flex;
  flex-direction: column;
}

.caseBox .caseInfo .designdesc span {
  font-size: .32rem;
  font-family: Source Han Sans CN;
  line-height: .57rem;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.caseBox .caseInfo .designdesc span:first-child {
  font-size: .32rem;
  line-height: .693333rem;
  margin-top: 0.1rem;
}

.caseBox .caseInfo .designdesc sup {
  font-size: .213333rem;
}

.caseBox .caseInfo .designdesc .rating {
  display: flex;
  align-items: center;
}

.caseBox .caseInfo .designdesc .rating .ratingimg {
  width: 1.146667rem;
  height: .24rem;
}

.caseBox .caseInfo .seemore {
  width: 3.173333rem;
  height: .773333rem;
}

.caseBox .FreeCustomizedDesign {
  width: 3.173333rem;
  height: .773333rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .32rem;
  font-family: Lantinghei SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: .293333rem;
  background-color: #0783E2;
  border-radius: .4rem;
  position: absolute;
  margin-top: -1rem;
  z-index: 12;
  right: .266667rem;
}

.ant-tabs-tab {
  margin: 0 !important;
  padding: .32rem !important;
}

.hoverButton {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 64%;
  transform: translateY(-64%);
  z-index: 100;
}

.hoverButton img {
  width: 1.066667rem;
  height: 1.066667rem;
  margin-bottom: .106667rem;
}

#content_video {
  margin: .233333rem .32rem .233333rem .32rem;
  position: relative;
}

#video {
  width: 9.36rem;
  height: 5rem !important;
}

#play {
  width: 1.28rem;
  height: 1.28rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

:global(.mySwipert) {
  height: 1.013333rem !important;
}

.textCity {
  padding-top: .010667rem;
}

.laba {
  width: .453333rem;
  height: .373333rem;
}

.renderTops {
  display: flex;
  flex-direction: row;
  margin: .426667rem .32rem;
  font-size: .32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #222222;
}

.renderTops_ {
  margin-right: .266667rem;
}

.DecorateTitele_ {
  margin-top: .126667rem;
  font-size: .293333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  height: 0.9rem;
  width: 6rem;
}

.border_dashed {
  width: 100%;
  height: 1px;
  border-style: dashed;
  margin: 10px 0;
}