
.container_titles{
    width: 100%;
    height: 7.253333rem;
}
.TeamRoles_text{
    width: 1.92rem;
    height: .666667rem;
    font-size: .48rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #3E3E3F;
    line-height: .666667rem;
    margin: .533333rem 0 .533333rem .32rem;
}
.TeamRoles_roles{
    width: 100%;
    height: 1.92rem;
    margin-bottom: .693333rem;
}
.customer_top_name{
    width: 1.493333rem;
    height: .533333rem;
    font-size: .373333rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #3E3E3F;
    line-height: .533333rem;
    margin-left: .453333rem;
}
.customer_content{
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
    margin-left: .053333rem;
    margin-bottom: .746667rem;
}
.customer{
    display: flex;
    flex-direction: column;
    width: 6.16rem;
    height: 4.666667rem;
    background: #FFFFFF;
    box-shadow: 0rem .053333rem .213333rem 0rem rgba(208,208,208,0.5);
    border-radius: .053333rem;
    justify-content: space-evenly;
    margin-left: .266667rem;
    padding: 0 .48rem;
}
.customer_top{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.customer_text{
    width: 5.44rem;
    height: 1.813333rem;
    font-size: .32rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3E3E3F;
    line-height: .453333rem;

}
.customer_top_img{
    width: 1.093333rem;
    height: 1.093333rem;
}
.ServiceProcess{
    width: 100%;
    height: 5.733333rem;
}
.AppointmentDesigner{
    display: flex;
    flex-direction: column;
    margin-top: .746667rem;
}
.AppointmentDesigner_content{
    height: 6.133333rem;
    display: flex;
    flex-direction: row;
    margin-left: .053333rem;
    overflow-x: scroll;
}
.AppointmentDesigner_s{
    width: 2.4rem;
    height: .666667rem;
    font-size: .48rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #3E3E3F;
    line-height: .666667rem;
    margin-left: .32rem;
    margin-bottom: .266667rem;
}
.designer{
    width: 3.786667rem;
    height: 5.093333rem;
    margin-left: .266667rem;
    position: relative;
}
.Mydesigner{
    width: 9.36rem;
    height: 1.226667rem;
    background: #0166B3;
    margin-left: .266667rem;
    margin-bottom: .8rem;
    text-align: center;
    color: white;
    font-size: .426667rem;
    line-height: 1.226667rem;
}
.container_content_{
    display: flex;
    flex-direction: row;
}
.designer_{
    width: 3.786667rem;
    height: 1.466667rem;
    background: rgba(0, 0, 0, 0.37);
    position: absolute;
    float: left;
    bottom: 0;
    margin-left: .266667rem;
}
.designer_name{
    height: .533333rem;
    font-size: .373333rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: .533333rem;
    padding-left: .293333rem;
    margin-top: .213333rem;
    margin-bottom: .133333rem;
}
.designer_jy_sj{
    height: .373333rem;
    font-size: .266667rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FF9741;
    line-height: .373333rem;
    margin-left: .053333rem;
    transform: scale(0.85);
}
.designer_{
    display: flex;
    flex-direction: column;
}
.designer_jy{
    display: flex;
    flex-direction: row;
}
.designer_jy_{
    display: flex;
    flex-direction: row;
    height: .373333rem;
    font-size: .213333rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: .373333rem;
    padding-left: .133333rem;
    transform: scale(0.85);
}