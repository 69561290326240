
.JuranSecurity_container_title{
    width: 100%;
    height: 29.333333rem;
}
.JuranSecurity_container_content{
    width: 100%;
    height: 25.44rem;
    margin-bottom: 1.333333rem;
}
.JuranSecurity_container{
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
.container_banner1{
    width: 100%;
    height: auto;
    margin: .6rem 0;
}
.JuranSecurity_img{
    width: 100%;
    height: auto;
}
.ant-carousel .slick-slide{
    background: white!important;
}
.ant-carousel .slick-dots li.slick-active{
    background: #1890ff;
    border-radius: 50%;
}
.ant-carousel .slick-dots li.slick-active button{
    background: #1890ff;
    border-radius: 50%;
}
li button{
    width: .18rem;
    margin: 0;
}
li{
    /* width: .18rem; */
    margin: 0;
    margin: 0;
}