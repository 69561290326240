.Install_innings_title{
    font-size: .426667rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 1.813333rem;
    height: 1.813333rem;
}
.Install_innings{
    width: 100%;
    // height: 1.813333rem;
    // background-color: #f8f8f8;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Install_innings_item3{
    width: 100%;
    height: 4rem;
}
.innings_bg3{
    width: 100%;
    height: 4rem;
}