ul {
    margin-bottom: 0;
}
.DesignerList{
    width: 100%!important;
}

.DesignerHeader {
    background-color: #fff;
    padding         : .533333rem .32rem;

    .DesignerHeader_top {
        display    : flex;
        align-items: center;

        .DesignerHeader_top_L {
            img.designer_avator {
                width        : 3.04rem;
                height       : 3.04rem;
                border-radius: 50%;
            }

            
        }

        .DesignerHeader_top_R {
            margin-left: .426667rem;


            .DesignerHeader_name_identity {
                .DesignerHeader_name {
                    font-size   : .426667rem;
                    font-family : PingFangSC-Medium, PingFang SC;
                    font-weight : 500;
                    color       : #3E3E3F;
                    line-height : .533333rem;
                    margin-right: .16rem;
                }

                .DesignerHeader_identity {
                    font-size  : .32rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color      : #0166B3;
                    line-height: .64rem;
                }

                span.praiseRate {
                    font-size  : .32rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color      : #3E3E3F;
                    line-height: .64rem;
                }

                .experience {
                    font-size  : .32rem;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color      : #0166B3;
                    line-height: .453333rem;
                   
                }
            }


            .designer_spans {
                display   : flex;
                flex-wrap : wrap;
                margin-top: .16rem;

                span.designer_span {
                    width          : 1.6rem;
                    height         : .586667rem;
                    background     : #E6F4FF;
                    border-radius  : .293333rem;
                    font-size      : .32rem;
                    font-family    : PingFangSC-Regular, PingFang SC;
                    font-weight    : 400;
                    color          : #0166B3;
                    line-height    : .453333rem;
                    margin-right   : .213333rem;
                    margin-bottom  : .266667rem;
                    display        : flex;
                    justify-content: center;
                    align-items    : center;
                }
            }
        }
    }

    .DesignerHeader_bottom {
        padding-top: .48rem;

        .DesignerHeader_bottom_desc {
            font-size         : .373333rem;
            font-family       : PingFangSC-Regular, PingFang SC;
            font-weight       : 400;
            color             : #979797;
            line-height       : .533333rem;
            display           : -webkit-box;
            overflow          : hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            text-overflow     : ellipsis;
        }

        .oneTOoneBtn {
            width          : 9.36rem;
            height         : 1.066667rem;
            background     : linear-gradient(180deg, #02BBFF 0%, #0364BD 100%);
            border-radius  : .053333rem;
            font-size      : .426667rem;
            font-family    : PingFangSC-Medium, PingFang SC;
            font-weight    : 500;
            color          : #FFFFFF;
            line-height    : .586667rem;
            display        : flex;
            justify-content: center;
            align-items    : center;
            margin         : .533333rem auto .32rem auto;
        }
    }
}

.richTextWrap{
    img{
        margin-top: 0.3rem;
    }
}
.DesignerList_bottom_desc{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
div.BookingService {
    background-color: #fff;

    div.sectionTitle {
        font-size     : .48rem;
        font-family   : PingFangSC-Medium, PingFang SC;
        font-weight   : 500;
        color         : #3E3E3F;
        line-height   : .666667rem;
        padding-left  : .32rem;
        // padding-bottom: .266667rem;
    }

    ul.BookingServiceList {
        display           : flex;
        flex-wrap         : wrap;
        // margin         : .533333rem;
        padding           : 0 .533333rem .533333rem;
        margin-top        : .533333rem;
        justify-content   : center;
        border-top        : .026667rem solid #E5E5E5;
        li.BookingServiceItem {
            display        : flex;
            flex-direction : column;
            justify-content: center;
            align-items    : center;
            width          : 2.88rem!important;
            height         : 3.786667rem;
            z-index: 10;
            img.BookingServiceItem_img {
                width : .853333rem;
                height: .853333rem;
            }

            span.BookingServiceItem_span {
                font-size  : .373333rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color      : #3E3E3F;
                line-height: .8rem;
            }

            div.nowSubscribe {
                margin-top     : .266667rem;
                width          : 2.266667rem;
                height         : .853333rem;
                background     : linear-gradient(180deg, #02BBFF 0%, #0364BD 100%);
                border-radius  : .426667rem;
                font-size      : .373333rem;
                font-family    : PingFangSC-Regular, PingFang SC;
                font-weight    : 400;
                color          : #FFFFFF;
                line-height    : .533333rem;
                display        : flex;
                justify-content: center;
                align-items    : center;
            }
        }
    }
}


div.storeWrap {
    background-color: #fff;

    div.sectionTitle {
        font-size  : .48rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color      : #3E3E3F;
        line-height: .666667rem;
        margin-left: .32rem;
    }

    div.storeList {
        display    : flex;
        align-items: center;
        border-top : .026667rem solid #E5E5E5;

        img.storeImg {
            width       : 3.493333rem;
            height      : 2rem;
            margin-right: .213333rem;
        }

        span.storeAddress {
            font-size  : .373333rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color      : #3E3E3F;
            line-height: .533333rem;
            display           : -webkit-box;
            overflow          : hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            text-overflow     : ellipsis;
        }

        img.iconmore {
            width : .48rem;
            height: .48rem;
        }

    }
}

div.designer_tab_name {
    font-size     : .373333rem;
    font-family   : PingFangSC-Regular, PingFang SC;
    font-weight   : 400;
    color         : #3E3E3F;
    line-height   : .533333rem;
    padding-bottom: .4rem;
}

div.designer_tab_info {
    font-size     : .373333rem;
    font-family   : PingFangSC-Regular, PingFang SC;
    font-weight   : 400;
    color         : #3E3E3F;
    line-height   : .533333rem;
    padding-bottom: .4rem;
}

div.designer_tab_prize {
    font-size  : .373333rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color      : #3E3E3F;
    line-height: .533333rem;

}

ul.DesignCase_tab {
    display        : flex;
    flex-wrap      : wrap;
    padding        : .266667rem .106667rem;
    justify-content: space-between;

    li.DesignCase_tab_list {
        width: 50%;
        display        : flex;
        flex-direction : column;
        justify-content: center;
        align-items    : center;
        // margin-right: .106667rem;
        margin-bottom  : .533333rem;
        font-size      : .373333rem;
        font-family    : PingFangSC-Regular, PingFang SC;
        font-weight    : 400;
        color          : #3E3E3F;
        line-height    : .693333rem;
        img.DesignCase_tab_list_img {
            width : 4.8rem;
            height: 5.013333rem;

        }
    }
}


.am-tabs-default-bar-tab-active {
    color: #0166B3 !important;
}

.am-tabs-default-bar-underline {
    border          : .04rem #0166B3 solid !important;
    background-color: #0166B3;
}