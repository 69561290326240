.animated-fade-in {
    animation-duration : .3s;
    animation-fill-mode: both;
    animation-name     : fadeIn;
    animation-timing-function: ease-out;
    display                  : block;

}

.animated-fade-out{
    animation-duration       : 1s;
    animation-fill-mode      : both;
    animation-name           : fadeOut;
    animation-timing-function: ease-out;
    display                  : none;
}

.animated-slide-in-up {
    animation-duration       : .6s;
    animation-fill-mode      : both;
    animation-name           : slideInUp;
    animation-timing-function: ease-out;
}

.animated-slide-out-down {
    animation-duration       : 1s;
    animation-fill-mode      : both;
    animation-name           : slideOutDown;
    animation-timing-function: ease-out;

}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform        : translate3d(0, 100%, 0);
        visibility       : visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform        : translate3d(0, 0, 0);
    }
}

@keyframes slideOutDown {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform        : translate3d(0, 0, 0);
    }

    to {
        -webkit-transform: translate3d(0, 100%, 0);
        transform        : translate3d(0, 100%, 0);
        visibility       : visible;
    }
}

.sheet_title {
    width        : 100%;
    height       : 2.1rem;
    font-size    : 1rem;
    font-weight  : 500;
    color        : #555555;
    text-align   : center;
    line-height  : 2.1rem;
    border-bottom: 0.05rem solid #f2f2f2;
    position     : relative;
}

.footer-href {
    display        : flex;
    flex-direction : column;
    justify-content: center;
}