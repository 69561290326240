
.container{
    width: 100%;
    height: auto;
}
.container-title{
    width: 100%;
    height: 13.546667rem;
    margin-top: .133333rem;
}
.container_content{
    margin: 0 .32rem;
}
.container_content_CompanyProfile{
    width: 100%;
    /* height: 7.36rem; */
    margin: .533333rem 0 .64rem 0;
}
.Company{
    height: .666667rem;
    font-size: .48rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #3E3E3F;
    line-height: .666667rem;
}
.Profile{
    width: 8.933333rem;
    /* height: 7.04rem; */
    font-size: .373333rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3E3E3F;
    line-height: .64rem;
    -webkit-background-clip: text;
}
.Profile_t{
    margin-top: .533333rem;
}
.video{
    width: 9.36rem;
    height: 5.253333rem;
    background: #3E3E3F;
}
.sixUnified{
    margin-top: .64rem;
}
.sixs{
    width: 100%;
    padding-right: .32rem;
}
.xUnified{
    height: .666667rem;
    font-size: .48rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #3E3E3F;
    line-height: .666667rem;
}
.development{
    height: .666667rem;
    font-size: .48rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #3E3E3F;
    line-height: .666667rem;
}
.Course_content_img{
    width: 5.413333rem;
    height: 2.64rem;
    background: #D8D8D8;
    border-radius: .106667rem;
}
.Course_content{
    width: 5.413333rem;
    height: 4.533333rem;
    margin-left: .266667rem;
    display: flex;
    flex-direction: column;
    margin-top: .266667rem;
}
.Course_content_text{
    width: 5.413333rem;
    font-size: .32rem;
    font-weight: 500;
    color: #3E3E3F;
    line-height: .453333rem;
    margin-top: .133333rem;
}
.Course{
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
    margin-right: .533333rem;
}
.Construction{
    width: 100%;
    height: .666667rem;
    font-size: .48rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #3E3E3F;
    line-height: .666667rem;
}
.Qualification{
    width: 100%;
    height: 7.653333rem;
    margin-top: .266667rem;
}
.ConstructionQualification{
    margin-top: .266667rem;
}
.bg_reward{
    width: 100%;
    height: 13.093333rem;
    margin-top: .533333rem;
}
  
  .StoreFooter .consulting {
    display: flex;
  }
  
  .StoreFooter .consulting .phoneNum {
    margin-left: .48rem;
  }
  
  .StoreFooter .consulting .phone_icon {
    width: .453333rem;
    height: .453333rem;
    margin-left: .266667rem;
  }
  
  .StoreFooter .address {
    padding-top: .426667rem;
  }
  .contaner_bottom{
      width: 100%;
      height: 5.36rem;
  }
  #content_videos {
    /* margin: .233333rem .32rem .233333rem .32rem; */
    position: relative;
}
.videos {
    width: 9.36rem;
    height: 5.253333rem;
    background: #3E3E3F;
}
#plays {
    width: 1.28rem;
    height: 1.28rem;
    position: absolute;
    top: 47%;
    left: 47%;
    transform: translate(-50%, -50%);
}