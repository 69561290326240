.commentsDetail_container_title{
    width: 100%;
    padding-left: 2.226667rem;
}
.yzzs{
    width: 5.546667rem;
    height: 2.266667rem;
}
.commentsDetail_container{
    width: 100%;
    height: 100%;
}
.commentsDetail_container_top{
    height: 1.066667rem;
    margin-left: 1.8rem;
    margin-top: .4rem;
}
.top_radius{
    width: 6.4rem;
    height: 1.066667rem;
    display: flex;
    flex-direction: row;
    font-size: .426667rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
    line-height: 1.066667rem;
}
/* .ant-tabs-tab{
    width: 3.2rem!important;
    height: 1.066667rem!important;
    background: #D4D4D4!important;
    border-top-left-radius: .666667rem!important;
    border-bottom-left-radius: .666667rem!important;
} */
    .btn {
    width: 3.2rem;
    height: 1.066667rem;
    background: #D4D4D4;
    border-top-left-radius: .666667rem;
    border-bottom-left-radius: .666667rem;
    }
    .btn-choose{
    background: #005BAB!important;
    }
     
    .btn1 {
    width: 3.2rem;
    height: 1.066667rem;
    background: #D4D4D4;
    border-top-right-radius: .666667rem;
    border-bottom-right-radius: .666667rem;
    }
  .commentsDetail_container_content{
      width: 100%;
      padding-bottom: 1.5rem;
      margin-top: .533333rem;
  }
