* {
  padding     : 0;
  margin      : 0;
}

a {
  color  : #666666!important;
  z-index: 5;
}
.style_active{
  color: rgb(18, 150, 219) !important;
}
.liulian{
  height: 0.2rem!important;
  margin: 0 .133333rem 0.2rem 0;
}

.neighbor_case_content_right_d{
    margin-top: .266667rem;
    padding-right: .32rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    height: 0.95rem;
}
.moduleListContent_desc_txts{
  width: 100%;
  /* height: 1.26rem!important; */
  color: #666;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: .266667rem;
}
.view_number{
  height: 0.2rem!important;
  /* margin: 0 .133333rem 0.2rem 0; */
}
.ant-input {
  width        : 6.08rem;
  height       : .906667rem;
  border-radius: .453333rem;
  border       : .026667rem solid #DEDEDE;
}
.StoreFooter a{
  color: white!important;
}
html,
body,
#root,
.app {
  width     : 100%;
  height    : 100%;
  background: white;
}

.app {
  display       : flex;
  flex-direction: column;
  overflow-x    : hidden;
}

.app_header {
  height    : 1.413333rem;
  width     : 100%;
  background: white;
  flex      : 0 0 1.413333rem;
}

.app_header_content {
  display         : flex;
  flex-direction  : row;
  justify-content : space-around;
  align-items     : center;
  height          : 1.386667rem;
  position        : fixed;
  z-index         : 999;
  background-color: #fff;
  width           : 100%;
}

/* .liwu {
  width      : 1.52rem;
  height     : 1.04rem;
  margin-top : .093333rem;
  margin-left: -0.15rem;
} */
.bider {
  width      : 1.52rem;
  height     : 1.04rem;
  margin-top : .093333rem;
  margin-left: -0.15rem;
  display: flex;
}
.bider_text {
  font-weight: bold;
  font-size: .32rem;
  transform: scale(0.8);
  line-height: .32rem;
  color: #222;
}
.bider_img {
  width: 1.52rem;
}

.logo {
  display       : flex;
  flex-direction: column;
}

.jrzs {
  width : 3.066667rem;
  height: .453333rem;
}

.jrjz {
  width        : 3.066667rem;
  height       : .266667rem;
  /* margin-top: .106667rem; */

}

.header_city {
  height      : .133333rem;
  font-weight : 450;
  color       : #0068B7;
  margin-right: -0.266667rem;
 
}

.app_footer {
  width           : 100%;
  /*height        : 1.653333rem;*/
  font-weight     : 500;
  flex            : 0 0 0 1.466667rem;
  background      : white;
  z-index         : 989;
  /*padding       : .266667rem 0 .426667rem 0;*/
  /* border-top   : .026667rem solid red; */
  position        : fixed;
  bottom          : 0;
  background-color: #fff;
}

.app_footer_content {
  width           : 100%;
  height          : 1.68rem;
  display         : flex;
  flex-direction  : row;
  justify-content : space-around;
  align-items     : center;
  position        : fixed;
  bottom          : 0;
  background-color: #fff;
  box-shadow      : 0rem -0.053333rem .186667rem 0rem #ddd, 0rem .106667rem 0rem 0rem #ddd;
  padding: 0 0.15rem 0 0;
}

a:active {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.app_footer_content_found {
  display       : flex;
  flex-direction: column;
  text-align    : center;
}

.one_key_ask_footer {
  position   : absolute;
  bottom     : .213333rem;
  margin-left: -0.213333rem;
}

.faxian {
  width : .586667rem;
  height: .453333rem;
}

.dthd {
  width : .533333rem;
  height: .48rem;
}

.yjzx {
  width        : .853333rem;
  height       : .933333rem;
  margin-bottom: 1.066667rem;
  margin-left  : 0.08rem;
}

.xzlg {
  width : .64rem;
  height: .48rem;
}

.yysj {
  width : .48rem;
  height: .48rem;
}

.app_main {
  flex             : 1;
  position         : relative;
  overflow-x       : hidden;
  padding-bottom: 1.333333rem;
}

.iconfont {
  font-size: .586667rem !important;
}

.footer-href {
  font-size : .32rem;
  margin-top: -0.106667rem;
}

.giftDialog {
  position : fixed;
  top      : 50%;
  left     : 50%;
  transform: translate(-50%, -50%);
  z-index  : 999;
  width    : 100vw;
  height   : 18.306667rem;
}

.giftMask {
  background: rgba(0, 0, 0, 0.45);
  position  : fixed;
  top       : 0;
  width     : 100vw;
  height    : 100vh;
  z-index   : 111;
}
.slider .am-carousel{
    margin-top:1.386667rem;
}
.envelopes_label{
    position: fixed;
    float: left;
    top: -1px;
    left: 27px;
    width: 12px;
    border-radius: 50%;
    line-height: 12px;
    height: 12px;
    z-index: 15;
    font-size: 8px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    background: #E33127;
}