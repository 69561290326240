.engineering_container{
    width: 100%;
    background: #ffffff!important;
    .engineering_img{
        width: 100%;
    }
    .container_banner1{
        border: none;
        margin-bottom: .7rem;
    }
       li button{
            width: .18rem!important;
            margin: 0!important;
        }
       li{
            width: .18rem!important;
            margin: 0!important;
            margin: 0!important;
        }
        .ant-carousel .slick-slide {
        background: white!important;
        }
        .ant-carousel .slick-dots li.slick-active{
            background: #1890ff;
            border-radius: 50%;
        }
        .ant-carousel .slick-dots li.slick-active button{
            background: #1890ff;
            border-radius: 50%;
        }
        .ant-carousel .slick-dots li{
            border-radius: 50%;
        }
        .ant-tabs-nav-list{
            width: 100%;
            flex-direction: row;
            justify-content: space-around;
        }
}
