.design_test-title {
	display        : flex;
	width          : 10.333333rem;
	height         : 10.666667rem;
	border-radius  : .453333rem;
	background-size: 100% 100% !important;

}

.design_dw {
	left      : 4.5rem;
	margin-top: .266667rem;
	position  : absolute;
	top       : 11rem;
}

.ant-modal-body {
	width  : 0;
	height : 0;
	padding: 0 !important;
}

.ant-modal-content {
	background-color: transparent !important;
	width           : 0;
	height          : 0;
	top             : -6.666667rem;
}

.design_test-title {
	margin-left : 0;
	padding-left: 0;

}

.design_title_ {
	width           : 100%;
	height          : .586667rem;
	font-size       : .426667rem;
	font-family     : PingFangSC-Semibold, PingFang SC;
	font-weight     : 600;
	color           : #333333;
	line-height     : .586667rem;
	// margin-top   : .533333rem;
	margin-bottom   : .266667rem;
}


.title_f_p {
	color      : #0166B3;
	margin     : 0 .08rem;
	font-weight: 600;
}

.design_title_f {
	display        : flex;
	flex-direction : row;
	width          : 100%;
	height         : .453333rem;
	font-size      : .32rem;
	font-family    : PingFangSC-Regular, PingFang SC;
	font-weight    : 400;
	color          : #9E9E9E;
	line-height    : .453333rem;
	justify-content: center;
	margin-top     : .08rem;
	margin-bottom  : .266667rem;
	width          : 100%;

}

.design_input {
	// margin-bottom: .213333rem !important;
	width        : 2.666667rem;
}

.errorTel {
	font-size  : .226667rem;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color      : #FF2020;
	line-height: .373333rem;
	margin-left: -2.133333rem;
}

.square {
	position   : absolute;
	z-index    : 99;
	margin-top : -0.96rem;
	margin-left: 2.133333rem;
	font-size  : .373333rem;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color      : #3E3E3F;
	line-height: .533333rem;
}

.design_button {
	width: 5.68rem;
	height: .92rem;
	background: linear-gradient(160deg, #E9536F 0%, #EF8E53 100%);
	border-radius: .56rem;
	font-size: .326667rem;
	color: #FFFFFF;
	line-height: .586667rem;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: .466667rem auto;
	/* margin-top: .693333rem; */
	// margin-bottom: .586667rem;
  }

input.ant-input {
	width        : 6.08rem !important;
	height       : .906667rem;
	border-radius: .453333rem;
	border       : .026667rem solid #DEDEDE;
}


.design_bottomSpan {
	font-size   : .32rem;
	font-weight : 400;
	color       : #0166B3;
	line-height : .453333rem;
	margin-right: .4rem;
}

.design_bottom_list {
	padding-top: .433333rem;
}

.price_sub_title {
	display        : flex;
	justify-content: center;
	flex-direction : column;

	.price_sub_title_m {
		width          : 6.613333rem;
		height         : 1.466667rem;
		background     : rgba(0, 0, 0, 0.05);
		border-radius  : .08rem;
		border         : .026667rem solid #DEDEDE;
		font-size      : .32rem;
		font-family    : PingFangSC-Regular, PingFang SC;
		font-weight    : 400;
		color          : #3E3E3F;
		line-height    : .453333rem;
		display        : flex;
		justify-content: space-between;
		align-items    : center;
		margin-left    : 1rem;
		margin-bottom  : .106667rem;
		padding        : 0 .693333rem 0 .213333rem;

		.price_sub_title_left {}

		.price_sub_title_right {
			span {
				font-size  : .906667rem;
				font-family: YournameD7GeneralNarrow;
				color      : #0166B3;
				line-height: .906667rem;
			}

		}
	}
}

.QuestionBox {
	top      : 50%;
	left     : 50%;
	transform: translate(-50%, -50%);
	position : absolute;
}

.Question_title {
	font-size      : .426667rem;
	font-family    : PingFangSC-Semibold, PingFang SC;
	font-weight    : 600;
	color          : #333333;
	line-height    : .586667rem;
	margin-top     : .533333rem;
	margin-bottom  : .266667rem;
	display        : flex;
	justify-content: center;
}

textarea.ant-input {
	width        : 6.426667rem !important;
	height       : 6.106667rem !important;
	background   : #FFFFFF !important;
	border-radius: .106667rem !important;
	border       : .026667rem solid #E1E1E1 !important;
	margin-bottom: .266667rem !important;

}

.ant-input-textarea-show-count::after {
	position  : absolute;
	margin-top: -.8rem;
	right     : .266667rem;

}

.storage_Bottom {
	margin-top: .433333rem;
}