.container_title {
    width: 100%;
    height: 66.24rem;
}

.container_content_like {
    height: .666667rem;
    font-size: .48rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #3E3E3F;
    line-height: .666667rem;
    margin-top: 1.6rem;
    margin-left: .32rem;
}

.container_title_n {
    width: 100%;
    margin-bottom: 0.1rem;
}

.container_title_ {
    width: 100%;
}

.container_title_1 {
    width: 2.7rem;
    height: 1.3rem;
    margin-left: .45rem;
    margin-top: .45rem;
}

.boxs {
    background-color: #F7F7F7;
    padding: .5rem 0 .95rem;
}

.container_content_like_img {
    width: 100%;
    height: 5.333333rem;
    margin-top: .266667rem;
}

.container_content_like_img1 {
    width: 100%;
    height: 5.333333rem;
    margin-top: .533333rem;
    margin-bottom: 1.066667rem;
}

.titles {
    padding: 1rem 0;
}