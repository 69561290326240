#root {
    // position: relative !important;
}

p {
    margin-bottom: 0;
}
.neighbor_case_content_right_a{
    font-size: .35555rem;
    font-family: Source Han Sans CN;
    font-weight: 600;
    color: #222222;
}
.headerImg {
    width : 100vw;
    height: 5.333333rem;
}

.storeList {
    padding: .373333rem .32rem .586667rem .32rem;
    /* display   : flex; */
    border-bottom: .026667rem solid #DEDEDE;
    // padding-bottom: 3.253333rem;
    
}

.storeList_top {
    display: flex;

}

.store_info p {

    font-size: .373333rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #3E3E3F;
    line-height: .533333rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /* text-overflow     : ellipsis; */
    -webkit-line-clamp: 2;

}

.store_img {
    width: 3.28rem;
    height: 2.88rem;
    background: #D8D8D8;
    border: .026667rem solid #979797;
    margin-right: .266667rem;

}

.store_phone {
    display: flex;
    margin-bottom: .373333rem;
    margin-top: .213333rem;
}

.phone_icon {
    width: .48rem;
    height: .48rem;
}

.phone {
    font-size: .373333rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3E3E3F;
    line-height: .533333rem;
    margin-left: .266667rem;

}

.store_btns {
    display: flex;
    align-items: center;
}

.lookHouse {
    width: 2.773333rem;
    height: .64rem;
    background: linear-gradient(180deg, #FFBB35 0%, #FF6018 100%);
    border-radius: .32rem;

    font-size: .373333rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: .533333rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.subscribeDesign {
    width: 2.773333rem;
    height: .64rem;
    background: linear-gradient(180deg, #02BBFF 0%, #025CAF 100%);
    border-radius: .32rem;
    font-size: .373333rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: .533333rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: .266667rem;
}

.store_address {
    font-size: .32rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3E3E3F;
    line-height: .533333rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EBEBEB;
    border-radius: .293333rem;
    margin-top: .266667rem;

}

.store_address img {
    width: .426667rem;
    height: .426667rem;
    margin-right: .266667rem;

}

// 底部栏
.StoreFooter {
    width         : 100vw;
    height        : 2.72rem;
    background    : #3E3E3F;
    bottom        : 0;
    // position      : fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: .32rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #F7F7F7;
    line-height: .453333rem;

    .consulting {
        display: flex;

        .phoneNum {
            margin-left: .48rem;
        }

        .phone_icon {
            width: .453333rem;
            height: .453333rem;
            margin-left: .266667rem;
        }
    }

    .address {
        padding-top: .426667rem;
    }
}