.gift_container {
    position: relative;
}

.design_test-title {
    display        : flex;
    justify-content: center;
    align-items    : center;
}

.gift_input_Box {
    display        : flex;
    justify-content: center;
    align-items    : center;
    flex-direction : column;
    margin-top     : 9.92rem;
    margin-bottom  : .64rem;

    .gift_input {
        width          : 6.773333rem !important;
        height         : 1.013333rem;
        background     : #FFFFFF;
        box-shadow     : 0rem 0rem .053333rem 0rem rgba(0, 0, 0, 0.5);
        border-radius  : .08rem;
        margin-bottom  : .426667rem;
        display        : flex;
        align-items    : center;
        justify-content: center;
        line-height    : 1.013333rem;
        font-size      : .64rem;
        padding        : 0  .266667rem !important;
       
        
    }


    .gift_input::-webkit-input-placeholder {
        font-size     : .64rem;
        font-family   : Helvetica;
        color         : #DADADA;
        text-align    : center;
        // line-height: 1.013333rem;
    }

    .ant-input:hover {
        border: none;
        border: 0;
    }

}



.gift_close {
    position   : absolute;
    margin-left: 8rem;
    font-size  : .533333rem;
    bottom     : -.32rem;
    color      : #fff;
}

.gift_Bottom_btn {
    width   : 4.64rem;
    height  : 1.173333rem;
    position: absolute;
    bottom  : -0.533333rem;
}