a{
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
}

/* a:active {
    color: #1296DB!important;
}
#aa{
    color: #1296DB;
} */