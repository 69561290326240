* {
    padding: 0;
}

.waterway {
    width: 100% !important;
}

.slider-slide {
    width: 100% !important;
}

.container {
    background-color: #fff;
    height          : auto;

    .container_title1 {
        width : 100%;
        height: auto;
    }

    .SuperiorDesign {
        margin         : 0 auto;
        margin-top     : .693333rem;
        width          : 7.44rem;
        height         : 1.68rem;
        display        : flex;
        justify-content: center;
    }

    .SuperiorDesignBig {
        margin    : .586667rem .32rem .693333rem .32rem;
        height    : 6.853333rem;
        box-sizing: border-box;
    }

    .designHome {
        width          : 5.333333rem;
        height         : 1.12rem;
        margin         : 0 auto;
        display        : flex;
        justify-content: center;
    }

    .designInput {
        margin-top    : .586667rem;
        padding       : 0 .586667rem;
        width         : 100%;
        display       : flex;
        flex-direction: column;

        .designInput_top {
            display        : flex;
            justify-content: space-between;

            .inputName {
                width        : 4.32rem;
                height       : .933333rem;
                background   : #FFFFFF;
                border       : .026667rem solid #CCCCCC;
                border-radius: .053333rem;
                margin-right : .16rem;
            }

            .inputArea {
                width        : 4.32rem;
                height       : .933333rem;
                background   : #FFFFFF;
                border       : .026667rem solid #CCCCCC;
                border-radius: .053333rem;

            }

        }

        .square {
            position   : absolute;
            right      : 1.2rem;
            margin-top : .266667rem;
            font-size  : .293333rem;
            font-family: Source Han Sans CN;

            color: #666666;
        }

        .inputPhone {
            margin-top   : .24rem;
            width        : 100%;
            height       : .933333rem;
            background   : #FFFFFF;
            border       : .026667rem solid #CCCCCC;
            border-radius: .053333rem;

        }

        input {
            padding: .16rem;
        }

        input::-webkit-input-placeholder {
            font-size  : .293333rem;
            font-family: Source Han Sans CN;

            color: #666666;

        }

        input::-moz-placeholder {
            font-size  : .293333rem;
            font-family: Source Han Sans CN;

            color: #666666;
        }

        input:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            font-size  : .293333rem;
            font-family: Source Han Sans CN;

            color: #666666;

        }

        input:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            font-size  : .293333rem;
            font-family: Source Han Sans CN;
            color      : #666666;

        }
    }

    .keydesign {
        width          : 7.44rem;
        height         : 1.04rem;
        margin         : .64rem auto .853333rem auto;
        display        : flex;
        justify-content: center;
    }

    .UnlockingDesign {
        width          : 8.346667rem;
        height         : 1.04rem;
        margin         : 0 auto;
        display        : flex;
        justify-content: center;
    }

    .lifeStyle {
        justify-content: center;
        align-items    : center;
        display        : flex;
        flex-wrap      : wrap;
        margin         : .586667rem .32rem .8rem .32rem;
        box-sizing     : border-box;

        .lifeStyleimg {
            width        : 4.64rem;
            height       : 2.32rem;
            margin-bottom: .106667rem;
        }

        .lifeStyle5 {

            width : 100%;
            height: 1.306667rem;
            margin: 0 auto;

        }
    }


    .subscribeDesigner {
        width          : 7.44rem;
        height         : 1.066667rem;
        margin         : 0 auto;
        display        : flex;
        justify-content: center;
    }

    .MaterialselectionTitle {
        width          : 8.48rem;
        height         : 1.68rem;
        margin         : .3rem auto .533333rem auto;
        display        : flex;
        justify-content: center;
    }

    .selection {
        margin         : .533333rem .32rem 0 .32rem;
        height         : .346667rem;
        margin         : 0 auto;
        display        : flex;
        justify-content: center;
    }

    .logo {
        width          : 100%;
        margin         : .453333rem .32rem 0 .32rem;
        height         : 4.853333rem;
        margin         : 0 auto;
        display        : flex;
        justify-content: center;
    }

    .MaterialScience {
        display        : flex;
        justify-content: center;
        padding-bottom : .48rem;

        .materialBox {
            position: relative;

            .material {
                margin-right      : .213333rem;
                width             : 4.586667rem;
                display           : flex;
                justify-content   : center;
                position          : relative;
                align-items       : center;
            }
            span {
                position : absolute;
                top      : 40%;
                transform: translate(-50%, -50%);
                left     : 50%;
                color    : #fff;
            }
        }


        // .active {
        //     position: relative;
        //     background-color: #016DC0 !important;
        // }

        // .active::after {
        //     position    : absolute;
        //     content     : '';
        //     margin-top  : 1.066667rem;
        //     border-right: .266667rem solid transparent;
        //     border-left : .266667rem solid transparent;
        //     border-top  : .533333rem solid #016DC0;
        // }

    }

    .materialproducts {
        margin         : .533333rem .32rem .853333rem .32rem;
        height         : 4.853333rem;
        margin         : 0 auto;
        display        : flex;
        justify-content: center;
    }

    .otherbrand {

        width          : 7.44rem;
        height         : 1.04rem;
        margin         : 0 auto;
        display        : flex;
        justify-content: center;
        margin-top     : .853333rem;
        margin-bottom: .853333rem;
    }

    .Eengineering {
        width          : 8.453333rem;
        height         : 1.706667rem;
        margin         : 0 auto;
        display        : flex;
        justify-content: center;
    }

    div.system {
        display              : grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap             : .48rem;
        margin               : .586667rem;

        .systemimg {
            width : 2.613333rem;
            height: 2.64rem;
        }


    }

    .waterway {
        margin         : .533333rem .32rem .853333rem .32rem;
        height         : 6.773333rem;
        margin         : 0 auto;
        display        : flex;
        justify-content: center;
        margin-bottom  : 1.066667rem;
    }

    .Learnmore {
        width          : 7.44rem;
        height         : 1.04rem;
        margin         : 0 auto;
        display        : flex;
        justify-content: center;
        margin-top     : .2rem;
    }

    .six {
        width          : 6.533333rem;
        height         : .4rem;
        margin         : 0 auto;
        display        : flex;
        justify-content: center;
        margin-top     : .853333rem;
    }

    .completionBond {
        width  : 100%;
        height : 10.133333rem;
        padding: .666667rem .32rem .853333rem .32rem;
    }

    .Visitconstructionsite {
        width          : 7.44rem;
        height         : 1.04rem;
        margin         : 0 auto;
        display        : flex;
        justify-content: center;
    }

    .vip {
        width          : 6.773333rem;
        height         : 2.073333rem;
        margin         : 0 auto;
        display        : flex;
        justify-content: center;
        padding-top: .5rem;
    }

    div.service {
        margin               : .533333rem .32rem .8rem .32rem;
        display              : grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows   : 1fr 1fr;
        grid-gap             : .106667rem;

        .serviceimg {
            width : 3.04rem;
            height: 2rem;
        }


    }

    // 优质案例
    .cases {
        height : 1.653333rem;
        padding: 0 .586667rem;
    }

    .caseBox {
        // width  : 100%;
        padding   : .426667rem .32rem .96rem .32rem;
        height    : 5.813333rem;
        color     : #FFFFFF;
        box-sizing: border-box;

        .casebg {
            // width : 100%;
            height: 5.813333rem;
        }

        .caseInfo {
            position        : absolute;
            z-index         : 11;
            display         : flex;
            flex-direction  : column;
            width           : 3.546667rem;
            height          : 5.813333rem;
            background-color: rgba($color: #000000, $alpha: 0.65);
            right           : 0.28rem;
            margin-top      : -5.813333rem;
            padding         : .213333rem;

            .simpleInfo {
                display        : flex;
                justify-content: space-between;
                align-items    : center;

                .infoLeft {
                    display       : flex;
                    flex-direction: column;

                    .designidentity {
                        font-size  : .24rem;
                        font-family: Source Han Sans CN;
                        line-height: .773333rem;
                    }

                    .designname {
                        font-size  : .32rem;
                        font-family: Source Han Sans CN;
                        line-height: .533333rem;
                    }
                }

                .headportrait {
                    width        : 1.28rem;
                    height       : 1.28rem;
                    border-radius: 50%;
                }
            }

            .designdesc {
                display       : flex;
                flex-direction: column;

                span {
                    font-size    : .32rem;
                    font-family  : Source Han Sans CN;
                    line-height  : .506667rem;
                    overflow     : hidden;
                    text-overflow: ellipsis;
                    white-space  : nowrap;
                }

                span:first-child {
                    font-size  : .32rem;
                    line-height: .693333rem;
                }

                sup {
                    font-size: .213333rem;
                }

                .rating {
                    display    : flex;
                    align-items: center;

                    .ratingimg {
                        width : 1.146667rem;
                        height: .24rem;
                    }
                }
            }



            .seemore {
                width : 3.173333rem;
                height: .773333rem;
            }
        }


    }

    .Bookingroom {
        width          : 100%;
        margin         : 0 auto;
        display        : flex;
        justify-content: center;
        align-items    : center;
        flex-direction : column;
        padding-top    : .96rem;

        .BookingroomTitle {

            font-size  : .48rem;
            font-family: Lantinghei SC;
            font-weight: bold;
            color      : #333333;
            line-height: 1.013333rem;

        }

        .appointmentsNumber {
            font-size  : .293333rem;
            font-family: Source Han Sans CN;
            color      : #666666;
            line-height: 1.013333rem;

            .people {
                color: #CF000E;
            }
        }

        .inputGroup {
            display       : flex;
            align-items   : center;
            flex-direction: column;

            input {
                width        : 8.293333rem;
                height       : 1.12rem;
                background   : #FFFFFF;
                border       : .026667rem solid #CCCCCC;
                margin-bottom: .266667rem;
                padding      : .373333rem;
            }

            input::-webkit-input-placeholder {

                font-size  : .346667rem;
                font-family: Source Han Sans CN;
                color      : #666666;

            }

            input::-moz-placeholder {
                font-size  : .346667rem;
                font-family: Source Han Sans CN;

                color: #666666;
            }

            input:-moz-placeholder {
                /* Mozilla Firefox 4 to 18 */
                font-size  : .346667rem;
                font-family: Source Han Sans CN;

                color: #666666;

            }

            input:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                font-size  : .346667rem;
                font-family: Source Han Sans CN;

                color: #666666;

            }
        }

        .BookingroomImg {
            width        : 8.293333rem;
            margin-bottom: .533333rem;
            height       : 1.12rem;
        }

        .notice {
            font-size    : .373333rem;
            font-family  : Source Han Sans CN;
            color        : #333333;
            margin-bottom: 1.493333rem;
        }
    }

    .copyrightWrap {
        display        : flex;
        justify-content: center;
        align-items    : center;
        margin-bottom  : .533333rem;

        p {
            width      : 6.933333rem;
            text-align : center;
            font-size  : .24rem;
            font-family: Source Han Sans CN;
            color      : #999999;
            line-height: .453333rem;
        }
    }

}


// 轮播图样式
.am-carousel-wrap-dot-active>span {
    background: #016DC0 !important;
    width     : .106667rem !important;
    height    : .106667rem !important;
}

.am-carousel-wrap-dot>span {
    width : .106667rem !important;
    height: .106667rem !important;
}


.slider-list {
    width: 100% !important;
}
.slider-frame {
    height: 5.813333rem !important;
}
